<template>
	<div class="card">
		<div class="card-body">
			<p>
				This account is part of these jurisdictions:
			</p>

			<div class="card-table mb-4">
				<table class="table table-hover">
					<thead>
						<tr>
							<th>ID</th>
							<th>Jurisdiction</th>
							<th>Role</th>
							<th>Department</th>
							<th>Notifications</th>
							<th>Added on</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="jurisdiction in person.jurisdictions"
							:key="`${jurisdiction.id}-person-jurisdiction`"
						>
							<td>
								{{ jurisdiction.id }}
							</td>
							<td>
								{{ jurisdiction.jurisdiction_name }}
							</td>
							<td>
								<span
									class="badge badge-xs "
									:class="
										roles[jurisdiction.role] ? roles[jurisdiction.role].class : 'bg-light text-gray'
									"
									bs-data-toggle="tooltip"
								>
									{{ roles[jurisdiction.role] ? roles[jurisdiction.role].name : 'Citizen' }}
								</span>
							</td>
							<td>
								<span v-if="jurisdiction.department_id">[{{ jurisdiction.department_id }}]</span>
							</td>
							<td>
								<span
									v-for="(alerts, notif) in jurisdiction.notifications"
									:key="`notif-${jurisdiction.id}-${notif}`"
									class="badge bg-light text-gray me-1 mb-1"
									><span class="text-dark">{{ notif }}</span
									>: {{ alerts.join(', ') }}</span
								>
							</td>
							<td>
								{{ jurisdiction.created_at | dateLocal }}
							</td>
							<td>
								<button
									class="btn btn-sm text-danger"
									@click="removePersonFromJurisdiction(jurisdiction)"
								>
									Remove
								</button>
							</td>
						</tr>
					</tbody>
					<tr v-if="person.jurisdictions.length === 0">
						<td colspan="9" class="text-center py-3">
							<span class="text-muted fw-bolder">Person not in jurisdiction 🤷</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

import heyGovApi, { handleResponseError } from '@/api.js'

export default {
	props: {
		person: Object,
	},
	name: 'PersonJurisdictions',
	data() {
		return {
			states: {
				departments: 'idle',
			},
			personData: {
				jurisdiction_id: '',
				role: 'CITIZEN',
				department_id: '',
			},
			departments: [],
		}
	},
	computed: {
		...mapState(['roles']),
	},
	methods: {
		removePersonFromJurisdiction(jurisdictionPerson) {
			if (confirm(`Really want to remove this jurisdiction role?`)) {
				heyGovApi
					.delete(
						`/super-secret-admin-endpoints/people/${jurisdictionPerson.person_id}/jurisdictions/${jurisdictionPerson.id}`
					)
					.then(() => {
						this.person.jurisdictions = this.person.jurisdictions.filter(
							j => j.id !== jurisdictionPerson.id
						)
						Vue.toasted.show('Person removed from jurisdiction')
					}, handleResponseError(`Failed to remove person from jurisdiction ({error})`))
			}
		},
	},
}
</script>
