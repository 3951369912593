<template>
	<div class="page-admin-workflows">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">Workflows</h2>
			</div>
			<div class="col-auto">
				<button
					class="btn btn-primary btn-sm btn-big-icon"
					data-bs-toggle="modal"
					data-bs-target="#modal-workflow"
				>
					<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add global workflow
				</button>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="row row-cols row-cols-4">
					<div class="col">
						<select class="form-select" v-model="filters.jurisdiction_id">
							<option :value="null">Global workflows</option>
							<option value="1">HeyVille</option>
							<option disabled>[search coming soon]</option>
						</select>
					</div>
					<div class="col">
						<select class="form-select" v-model="filters.trigger">
							<option :value="null">All triggers</option>
							<option value="form">Forms</option>
							<option value="venue">Meetings</option>
							<option value="payment">Payments</option>
							<option value="venue">Venues</option>
						</select>
					</div>
				</div>

				<hr class="bg-primary-100" />

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Workflow</th>
								<th>Trigger</th>
								<th>Actions</th>
								<th>Added by</th>
								<th>Runs</th>
								<th>Last run</th>
							</tr>
						</thead>
						<tbody>
							<router-link
								v-for="workflow in workflows"
								:key="workflow.id"
								:to="`/heygov-admin/workflows/${workflow.id}`"
								tag="tr"
							>
								<td>{{ workflow.name }}</td>
								<td>
									<code>{{ workflow.trigger }}</code>
								</td>
								<td>
									<span
										v-for="action in workflow.actions"
										:key="action.id"
										class="badge"
										:class="
											actions[action.action]
												? actions[action.action].class
												: 'bg-neutral-100 text-dark'
										"
										>{{
											actions[action.action] ? actions[action.action].name : action.action
										}}</span
									>
								</td>
								<td>[{{ workflow.created_by }}]</td>
								<td>{{ workflow.runs.toLocaleString() }}</td>
								<td>
									<span v-if="workflow.last_run_at">{{
										workflow.last_run_at | dateToNow(true)
									}}</span>
								</td>
							</router-link>

							<tr v-if="states.workflows === 'loading'">
								<td colspan="6" class="text-center py-5">Loading...</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<!-- Modal - Add workflow -->
		<div class="modal fade" id="modal-workflow" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="addWorkflow" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title my-0">
							Create global workflow
						</h5>

						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="form-group mb-3">
							<label class="form-label" for="workflow-name">Workflow name</label>

							<input type="text" class="form-control" id="workflow-name" required />
						</div>

						<div class="form-group">
							<label class="form-label" for="workflow-trigger">Trigger</label>

							<input
								type="text"
								class="form-control"
								id="workflow-trigger"
								required
								placeholder="form.created, payment.failed, etc."
							/>
						</div>
					</div>
					<div class="modal-footer justify-content-between">
						<button class="btn btn-sm" type="button" data-bs-dismiss="modal">Cancel</button>
						<button class="btn btn-sm btn-primary px-5">Create</button>
					</div>
				</form>
			</div>
		</div>
		<!-- END Modal - Edit workflow action -->
	</div>
</template>

<script>
import { mapState } from 'vuex'

import heyGovApi, { handleResponseError } from '@/api.js'

export default {
	name: 'AdminWorkflows',
	components: {},
	data() {
		return {
			states: {
				workflows: 'loading',
			},

			filters: {
				trigger: null,
				jurisdiction_id: null,
			},

			workflows: [],

			actions: {
				'send-email': {
					name: 'Send email',
					description: 'Send an email on behald of HeyGov',
					class: 'bg-primary-50 text-primary-400',
				},
			},
		}
	},

	created() {
		this.loadWorkflows()
	},
	computed: {
		...mapState(['apiUrl']),
	},
	methods: {
		loadWorkflows() {
			this.workflows = []
			this.states.workflows = 'loading'

			const params = {
				...this.filters,
			}

			heyGovApi
				.get('super-secret-admin-endpoints/workflows', { params })
				.then(({ data }) => {
					this.workflows.push(...data)
				}, handleResponseError('Failed to load workflows ({error})'))
				.finally(() => {
					this.states.workflows = 'loaded'
				})
		},

		addWorkflow() {},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.loadWorkflows()
			},
		},
	},
}
</script>
