const chartsCommonData = {
	data: [],
	fill: false,
	tension: 0.1,
	borderWidth: 2,
	pointBorderWidth: 1,
	pointRadius: 3,
	segment: {
		borderDash: ctx => {
			const isLastSegment = ctx.chart.data.datasets[ctx.datasetIndex].data.length - 1 === ctx.p1DataIndex

			return isLastSegment ? [6, 6] : undefined
		},
	},
	pointHoverBorderWidth: 2,
	pointHoverRadius: 5,
}

const generateChartDataset = (data = {}, options = {}) => {
	const dataset = {
		fill: false,
		tension: 0.1,
		borderWidth: 2,
		pointBorderWidth: 1,
		pointRadius: 3,
		pointHoverBorderWidth: 2,
		pointHoverRadius: 5,
		...data,
	}

	if (options.dashLastSegment) {
		dataset.segment = {
			borderDash: ctx => {
				const isLastSegment = ctx.chart.data.datasets[ctx.datasetIndex].data.length - 1 === ctx.p1DataIndex

				return isLastSegment ? [6, 6] : undefined
			},
		}
	}

	return dataset
}

const jChart = {
	labels: [],
	datasets: [
		{
			data: [],
			backgroundColor: [
				'rgb(109, 249, 177)',
				'rgb(52, 55, 226)',
				'rgb(21, 129, 237)',
				'rgb(46, 153, 160)',
				'rgb(4, 201, 109)',
				'rgb(255, 168, 30)',
				'rgb(46, 79, 201)',
				'rgb(255, 50, 64)',
				'rgb(62, 37, 221)',
				'rgb(82, 229, 143)',
				'rgb(198, 30, 216)',
				'rgb(43, 175, 70)',
				'rgb(206, 255, 102)',
				'rgb(88, 132, 0)',
				'rgb(142, 11, 22)',
				'rgb(203, 97, 244)',
			],
			borderWidth: 1,
			hoverOffset: 2,
		},
	],
}

const jChartOptions = {
	cutout: 50,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: true,
			text: 'by Jurisdiction',
			font: 'Agradir',
		},
	},
}

export { generateChartDataset, chartsCommonData, jChart, jChartOptions }
