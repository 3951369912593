<template>
	<div class="card">
		<div class="card-body">
			<div class="card-table">
				<table class="table table-hover">
					<thead>
						<tr class="text-uppercase text-muted fw-normal">
							<td>ID</td>
							<td><small>Jurisdiction</small></td>
							<td>Form</td>
							<td>Status</td>
							<td>Received on</td>
							<td>Expiry</td>
							<td>Payment status</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="request in personFormRequests" :key="request.id">
							<td>{{ request.id }}</td>
							<td>
								{{ request.jurisdiction_name }}
							</td>
							<td class="text-truncate" style="max-width: 300px;">
								<router-link
									:to="
										`/${request.jurisdiction_slug}/forms/${request.form.slug}/request-review/${request.uuid}`
									"
									>{{ request.form.name }} - {{ request.application_number }}</router-link
								>
							</td>
							<td>
								<span
									v-if="request.status && formRequestStatuses"
									class="badge"
									:class="formRequestStatuses[request.status].class"
								>
									{{ formRequestStatuses[request.status].name }}
								</span>
							</td>
							<td>
								{{ (request.sent_at || request.created_at) | dateLocal }}
							</td>
							<td>
								<span v-if="request.valid_until">{{ request.valid_until | dateLocal }}</span>
							</td>
							<td>
								<payment-status
									v-if="'payment' in request.answers"
									:payment="request.answers.payment"
								/>
								<i v-else class="text-muted"><small>no payment</small></i>
							</td>
						</tr>
					</tbody>
					<tfoot>
						<tr v-if="states.formRequests === 'loading'">
							<td colspan="9" class="text-center py-3">
								<div class="spinner-border spinner-border-sm" role="state"></div>
							</td>
						</tr>
						<tr v-else-if="states.formRequests === 'loaded' && !personFormRequests.length">
							<td colspan="9" class="text-center py-3">
								<span class="text-muted fw-bolder">There are no form requests yet 🤷</span>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { isObject } from 'lodash'

import heyGovApi, { handleResponseError } from '@/api.js'
import { formRequestStatuses } from '@/actions/forms.js'
import { paymentStatuses } from '@/actions/payments.js'

import PaymentStatus from '@/components/payments/PaymentStatus.vue'

export default {
	name: 'PersonFormRequests',
	components: { PaymentStatus },
	props: {
		person: Object,
	},
	data() {
		return {
			states: {
				formRequests: 'loading',
			},
			personFormRequests: [],
			formRequestStatuses,
			paymentStatuses,
		}
	},
	created() {
		this.loadFormRequests()
	},
	methods: {
		loadFormRequests() {
			const params = { person_id: this.person.id }
			this.states.formRequests = 'loading'

			heyGovApi.get('super-secret-admin-endpoints/form-requests', { params }).then(data => {
				data.data.forEach(request => {
					for (let value of Object.entries(request.answers)) {
						if (isObject(value[1]) && 'price' in value[1]) {
							request.answers.payment = value[1]
						}
					}
				})
				this.personFormRequests = data.data

				this.states.formRequests = 'loaded'
			})
			error => {
				handleResponseError('Error loading form submissions ({error})')(error)
				this.states.formRequests = 'error'
			}
		},
	},
}
</script>
