<template>
	<div class="jurisdiction-page">
		<div class="card mb-4">
			<div class="card-body px-1 py-3">
				<span class="badge bg-warning-50 text-dark position-absolute" style="top: -12px; right: -6px"
					>Lifetime totals</span
				>

				<div class="row text-center mb-2 gx-1">
					<div class="col">
						<p class="mb-0 text-muted">Jurisdictions</p>
					</div>
					<div class="col">
						<p class="mb-0 text-muted">Accounts</p>
					</div>
					<div class="col">
						<p class="mb-0 text-muted">
							{{ statsTotals ? `${statsTotals.payments.toLocaleString()} payments` : 'Payments' }}
						</p>
					</div>
					<div class="col">
						<p class="mb-0 text-muted">Form submissions</p>
					</div>
					<div class="col">
						<p class="mb-0 text-muted">Reservations</p>
					</div>
					<div class="col">
						<p class="mb-0 text-muted">311 requests</p>
					</div>
					<div class="col">
						<p class="mb-0 text-muted">Meetings</p>
					</div>
				</div>

				<div class="row text-center align-items-center gx-1">
					<div class="col">
						<h5 v-if="statsTotals" class="display-6 my-0">{{ statsTotals.jurisdictions }}</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
					</div>

					<div class="col">
						<h5 v-if="statsTotals" class="display-6 my-0" :title="statsTotals.people.toLocaleString()">
							{{ shortNumber(statsTotals.people) }}
						</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
					</div>

					<div class="col">
						<h5
							v-if="statsTotals"
							class="display-6 my-0"
							:title="statsTotals.paymentsAmount.toLocaleString()"
						>
							{{ shortNumber(statsTotals.paymentsAmount, 1, { style: 'currency', currency: 'usd' }) }}
						</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
					</div>

					<div class="col">
						<h5
							v-if="statsTotals"
							class="display-6 my-0"
							:title="statsTotals.formRequests.toLocaleString()"
						>
							{{ shortNumber(statsTotals.formRequests) }}
						</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
					</div>

					<div class="col">
						<h5
							v-if="statsTotals"
							class="display-6 my-0"
							:title="statsTotals.venueBookings.toLocaleString()"
						>
							{{ shortNumber(statsTotals.venueBookings) }}
						</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
					</div>

					<div class="col">
						<h5 v-if="statsTotals" class="display-6 my-0" :title="statsTotals.threads.toLocaleString()">
							{{ shortNumber(statsTotals.threads) }}
						</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
					</div>

					<div class="col">
						<h5 v-if="statsTotals" class="display-6 my-0" :title="statsTotals.meetings.toLocaleString()">
							{{ shortNumber(statsTotals.meetings) }}
						</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
					</div>
				</div>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-body px-1 py-3">
				<select
					v-if="states.rangeEdit"
					class="position-absolute"
					style="top: -12px; right: -6px"
					v-model="filters.date_range"
				>
					<option value="12-months">Last 12 months</option>
					<option value="3-months">Last 3 months</option>
				</select>
				<span
					v-else
					class="badge bg-primary-50 text-dark position-absolute"
					style="top: -12px; right: -6px"
					@click="states.rangeEdit = true"
					>Last {{ filters.date_range }}</span
				>

				<div class="row mb-3 gx-2 mx-1">
					<div class="col">
						<p class="mb-0 text-muted">Jurisdictions</p>
					</div>
					<div class="col">
						<div class="bg-neutral-50 rounded-1 px-2 py-1">
							<p class="mb-1 text-muted">Accounts</p>

							<h5
								v-if="states.stats === 'loaded'"
								class="display-6 my-0"
								:title="stats.people.total.toLocaleString()"
							>
								{{ shortNumber(stats.people.total) }}
							</h5>
							<h5 v-else class="display-6 text-muted my-0">-</h5>
						</div>
					</div>
					<div class="col">
						<div class="bg-neutral-50 rounded-1 px-2 py-1">
							<p class="mb-1 text-muted">
								{{
									states.stats === 'loaded'
										? `${stats.payments.total.toLocaleString()} payments`
										: 'Payments'
								}}
							</p>
							<h5 v-if="states.stats === 'loaded'" class="display-6 my-0">
								{{ shortNumber(stats.payments.amount, 1, { style: 'currency', currency: 'usd' }) }}
							</h5>
							<h5 v-else class="display-6 text-muted my-0">-</h5>
						</div>
					</div>
					<div class="col">
						<div class="rounded-1 px-2 py-1 bg-neutral-50">
							<p class="mb-1 text-muted">Form submissions</p>
							<h5
								v-if="stats.formRequests"
								class="display-6 my-0"
								:title="stats.formRequests.total.toLocaleString()"
							>
								{{ shortNumber(stats.formRequests.total) }}
							</h5>
							<h5 v-else class="display-6 text-muted my-0">-</h5>
						</div>
					</div>
					<div class="col">
						<div class="rounded-1 px-2 py-1 bg-neutral-50">
							<p class="mb-1 text-muted">Reservations</p>
							<h5
								v-if="stats.venueBookings"
								class="display-6 my-0"
								:title="stats.venueBookings.total.toLocaleString()"
							>
								{{ shortNumber(stats.venueBookings.total) }}
							</h5>
							<h5 v-else class="display-6 text-muted my-0">-</h5>
						</div>
					</div>
					<div class="col">
						<div class="bg-neutral-50 rounded-1 px-2 py-1">
							<p class="mb-1 text-muted">311 requests</p>

							<h5
								v-if="states.stats === 'loaded'"
								class="display-6 my-0"
								:title="stats.requests.total.toLocaleString()"
							>
								{{ shortNumber(stats.requests.total) }}
							</h5>
							<h5 v-else class="display-6 text-muted my-0">-</h5>
						</div>
					</div>
					<div class="col">
						<div class="bg-neutral-50 rounded-1 px-2 py-1">
							<p class="mb-1 text-muted">Meetings</p>

							<h5
								v-if="states.stats === 'loaded'"
								class="display-6 my-0"
								:title="stats.meetings.total.toLocaleString()"
							>
								{{ shortNumber(stats.meetings.total) }}
							</h5>
							<h5 v-else class="display-6 text-muted my-0">-</h5>
						</div>
					</div>
				</div>

				<div class="stats-chart">
					<div v-if="states.stats === 'loaded'">
						<stats-line-chart
							:data="statsChart"
							:options="statsChartOptions"
							:height="350"
						></stats-line-chart>
					</div>
					<div v-else class="text-center" style="min-height: 350px">
						<span class="spinner-border text-primary-400 mt-4" role="status"></span>
						<p class="mt-2 mb-0 text-neutral-400">Generating fresh stats for you</p>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<div class="card">
					<div class="card-header">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="card-title my-0">Jurisdictions with online payments</h5>
							<a
								v-if="paymentReportsDownload"
								:href="paymentReportsDownload"
								class="btn btn-sm btn-outline-dark"
								download="heygov-payments-reports.csv"
								>Export</a
							>
						</div>
					</div>
					<div class="card-body">
						<div class="card-table">
							<table class="table table-sm table-hover">
								<thead>
									<tr>
										<th>Name</th>
										<th>Payments</th>
										<th>Payouts</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="line in paymentReports.slice(0, 15)" :key="line.slug">
										<td>
											<router-link :to="`/${line.slug}`">{{ line.name }}</router-link>
										</td>
										<td>{{ line.payments.toLocaleString() }}</td>
										<td>{{ line.payouts.toLocaleString() }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col"></div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
//import Vue from 'vue'

import heyGovApi, { handleResponseError } from '@/api.js'
import { generateChartDataset } from '@/actions/charts.js'

import StatsLineChart from '@/components/StatsLineChart.vue'

export default {
	name: 'AdminDashboard',
	components: { StatsLineChart },
	data() {
		return {
			states: {
				rangeEdit: true,
				stats: 'loading',
			},
			filters: {
				jurisdiction_id: 'live',
				date_range: this.$route.query.date_range || localStorage.getItem('admin-date-range') || '12-months',
			},

			// stats data
			statsTotals: null,
			stats: {
				people: null,
				payments: null,
				formRequests: null,
				venueBookings: null,
				requests: null,

				widgetShow: [],
			},

			statsChartOptions: {
				responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				maintainAspectRatio: false,
				legend: {
					//display: false,
				},
				scales: {
					x: {
						grid: {
							display: false,
						},
					},
				},
			},
			statsChart: {
				labels: [],
				datasets: [
					generateChartDataset(
						{
							label: 'Account sign-ups',
							borderColor: '#222222',
							backgroundColor: '#94a3b8',
							pointBorderColor: '#222222',
							pointBackgroundColor: '#222222',
						},
						{ dashLastSegment: true }
					),
					generateChartDataset(
						{
							label: 'Payments',
							borderColor: '#02cea7',
							backgroundColor: '#f0fffc',
							pointBorderColor: '#02cea7',
							pointBackgroundColor: '#02cea7',
						},
						{ dashLastSegment: true }
					),
					generateChartDataset(
						{
							label: 'Form submissions',
							borderColor: '#22a6ff',
							backgroundColor: '#eff9ff',
							pointBorderColor: '#22a6ff',
							pointBackgroundColor: '#22a6ff',
						},
						{ dashLastSegment: true }
					),
					generateChartDataset(
						{
							label: 'Venue reservations',
							borderColor: '#7879f1',
							backgroundColor: '#f7f6ff',
							pointBorderColor: '#7879f1',
							pointBackgroundColor: '#7879f1',
						},
						{ dashLastSegment: true }
					),
					generateChartDataset(
						{
							label: '311 requests',
							borderColor: '#FF5D46',
							backgroundColor: '#FFF3F1',
							pointBorderColor: '#FF5D46',
							pointBackgroundColor: '#FF5D46',
						},
						{ dashLastSegment: true }
					),
					generateChartDataset(
						{
							label: 'Meetings',
							borderColor: '#0e7490',
							backgroundColor: '#06b6d4',
							pointBorderColor: '#0e7490',
							pointBackgroundColor: '#0e7490',
						},
						{ dashLastSegment: true }
					),
					generateChartDataset(
						{
							label: 'Minutes generated',
							borderColor: '#06b6d4',
							backgroundColor: '#67e8f9',
							pointBorderColor: '#06b6d4',
							pointBackgroundColor: '#06b6d4',
						},
						{ dashLastSegment: true }
					),
				],
			},

			paymentReports: [],
			paymentReportsDownload: null,
		}
	},

	created() {
		this.loadLifetimeStats()
		this.loadStats()
		this.loadPaymentReports()
	},
	computed: {
		...mapState(['apiUrl', 'features']),
	},
	methods: {
		loadLifetimeStats() {
			heyGovApi('super-secret-admin-endpoints/heygov-lifetime-totals').then(({ data }) => {
				this.statsTotals = data
			})
		},

		loadStats() {
			this.states.stats = 'loading'

			const params = {
				...this.filters,
			}

			Promise.all([
				heyGovApi.get('super-secret-admin-endpoints/people-stats', { params }),
				heyGovApi.get('super-secret-admin-endpoints/payments-stats', { params }),
				heyGovApi.get('super-secret-admin-endpoints/form-requests-stats', { params }),
				heyGovApi.get('super-secret-admin-endpoints/venue-bookings-stats', { params }),
				heyGovApi.get('super-secret-admin-endpoints/threads-stats', { params }),
				heyGovApi.get('super-secret-admin-endpoints/meetings-stats', { params }),
				//heyGovApi.get('admin/analytics/app-open', { params: this.filters }),
			]).then(([people, payments, formRequests, venueBookings, requests, meetings]) => {
				this.stats.people = people.data
				this.stats.payments = payments.data
				this.stats.formRequests = formRequests.data
				this.stats.venueBookings = venueBookings.data
				this.stats.requests = requests.data
				this.stats.meetings = meetings.data

				//this.stats.appOpens = appOpens.data

				this.statsChart.labels = []
				this.statsChart.datasets[0].data = []
				this.statsChart.datasets[1].data = []
				this.statsChart.datasets[2].data = []
				this.statsChart.datasets[3].data = []
				this.statsChart.datasets[4].data = []
				this.statsChart.datasets[5].data = []
				this.statsChart.datasets[6].data = []

				for (let month in people.data.byMonth) {
					this.statsChart.labels.push(month)
					this.statsChart.datasets[0].data.push(people.data.byMonth[month].total)
					this.statsChart.datasets[1].data.push(payments.data.byMonth[month].count)
					this.statsChart.datasets[2].data.push(formRequests.data.byMonth[month].total)
					this.statsChart.datasets[3].data.push(venueBookings.data.byMonth[month].count)
					this.statsChart.datasets[4].data.push(requests.data.byMonth[month].count)
					this.statsChart.datasets[5].data.push(meetings.data.byMonth[month].total)
					this.statsChart.datasets[6].data.push(meetings.data.byMonth[month].minutes_generated)
				}

				this.states.stats = 'loaded'
			})
		},

		loadPaymentReports() {
			heyGovApi('super-secret-admin-endpoints/payments-reports?limit=300').then(({ data }) => {
				this.paymentReports.push(...data)

				if (data.length) {
					const csv = [Object.keys(data[0]).join(','), ...data.map(line => Object.values(line).join(','))]
					const blob = new Blob([csv.join('\n')], { type: 'text/csv' })
					this.paymentReportsDownload = URL.createObjectURL(blob)
				}
			}, handleResponseError(`Couldn't load payment reports ({error})`))
		},

		shortNumber(number, decimals = 1, options = {}) {
			let suffix = ''

			if (number > 1000000) {
				number = number / 1000000
				suffix = 'm'
			} else if (number > 1000) {
				number = number / 1000
				suffix = 'k'
			}

			return (
				number.toLocaleString('default', {
					...options,
					minimumFractionDigits: 0,
					maximumFractionDigits: decimals,
				}) + suffix
			)
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.loadStats()

				localStorage.setItem('admin-date-range', this.filters.date_range)
			},
		},
	},
}
</script>
