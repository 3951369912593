<template>
	<div class="page-admin-workflows">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">
					<router-link to="/heygov-admin/workflows">Workflows</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2 me-1" />
					{{ workflow ? workflow.name : $route.params.id }}
				</h2>
			</div>
			<div class="col-auto">
				<button class="btn btn-outline-primary btn-sm" disabled>
					...
				</button>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-body p-3">
				<div class="row">
					<div class="col text-center">
						<p class="mb-4 text-neutral-500">Running</p>

						<div v-if="workflow" class="form-check form-check-inline form-switch m-0 p-0">
							<input
								class="form-check-input m-0"
								type="checkbox"
								role="switch"
								v-model="workflow.active"
							/>
						</div>
					</div>
					<div class="col text-center">
						<p class="mb-1 text-neutral-500">Runs</p>
						<h6 class="display-3 my-0">{{ workflow ? workflow.runs : '-' }}</h6>
					</div>
					<div class="col text-center">
						<p class="mb-3 text-neutral-500">Last run</p>
						<h6 class="display-6 my-0">
							<template v-if="workflow && workflow.last_run_at">{{
								workflow.last_run_at | dateToNow(true)
							}}</template>
							<template v-else>-</template>
						</h6>
					</div>
				</div>
			</div>
		</div>

		<ul class="nav nav-tabs mb-3 mt-4">
			<li class="nav-item">
				<router-link
					:to="`/heygov-admin/workflows/${$route.params.id}`"
					class="nav-link"
					:class="{ active: $route.name === 'AdminWorkflowsEdit' }"
					>Workflow</router-link
				>
			</li>
			<li class="nav-item">
				<router-link
					:to="`/heygov-admin/workflows/${$route.params.id}/runs`"
					class="nav-link disabled"
					:class="{ active: $route.name === 'AdminWorkflowsRuns' }"
					>Runs
					<span class="badge bg-light" :class="workflow && workflow.runs ? 'text-dark' : 'text-gray'">{{
						workflow ? workflow.runs : '-'
					}}</span></router-link
				>
			</li>
		</ul>

		<div v-if="workflow" class="card">
			<div class="card-body">
				<div class="row justify-content-center mb-4 mt-4">
					<div class="col-4">
						<div class="bg-neutral-100 border rounded-1 p-3 text-center">
							<div class="p-3">
								<p class="text-dark mb-3"><strong>Trigger</strong></p>

								<p class="mb-0">
									<code>{{ workflow.trigger }}</code>
								</p>
							</div>
						</div>
					</div>
				</div>

				<div v-for="action in workflow.actions" :key="action.id">
					<h1 class="text-center mb-4">👇</h1>

					<div class="row justify-content-center mb-4">
						<div class="col-4 on-parent">
							<div
								class="border rounded-1 p-3"
								:class="`bg-${actions[action.action] ? actions[action.action].color : 'danger'}-50`"
							>
								<div class="row align-items-center mb-1">
									<div class="col">
										<strong class="text-dark">Action</strong>
									</div>
									<div class="col-auto">
										<div class="dropdown show-on-hover">
											<button
												class="btn btn-sm btn-outline-dark"
												type="button"
												data-bs-toggle="dropdown"
												title="Edit action"
											>
												<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
											</button>
											<ul class="dropdown-menu">
												<li>
													<button class="dropdown-item" @click="editAction(action)">
														Edit action
													</button>
												</li>
												<li>
													<button class="dropdown-item text-danger-400">Remove</button>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<template v-if="actions[action.action]">
									<p class="text-center mb-4">
										<span
											class="badge"
											:class="
												`bg-${actions[action.action].color}-100 text-${
													actions[action.action].color
												}-400`
											"
											><font-awesome-icon
												:icon="['fas', actions[action.action].icon]"
												class="me-1"
											/>
											{{ actions[action.action].name }}</span
										>
									</p>
									<p class="mb-0">{{ actions[action.action].renderText(action.data) }}</p>
								</template>
								<template v-else>
									<p class="text-center mb-4">
										<span class="badge bg-danger-100 text-danger-400">{{ action.action }}</span>
									</p>
									<p class="mb-0">Special action that runs custom code</p>
								</template>
							</div>
						</div>
					</div>
				</div>

				<h1 class="text-center mb-4">👇</h1>

				<div class="row justify-content-center mb-4">
					<div class="col-3">
						<div
							class="border border-dashed bg-neutral-100 text-dark rounded-1 py-4 text-center cursor-pointer"
							data-bs-toggle="modal"
							data-bs-target="#modal-action-add"
						>
							Add action
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal - Edit workflow action -->
		<div class="modal fade" id="modal-action-edit" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form v-if="selectedAction" @submit.prevent="saveSelectedAction" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title my-0">
							Edit 'Send email' action
						</h5>

						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div v-if="selectedAction.action === 'send-email'" class="modal-body">
						<div class="form-group mb-3">
							<label class="form-label" for="action-email-from">From</label>

							<div class="input-group">
								<input
									type="text"
									class="form-control"
									id="action-email-from"
									v-model="selectedAction.data.fromParts[0]"
									required
									placeholder="heygov"
									aria-label="Email label"
								/>
								<span class="input-group-text">@</span>
								<select
									class="form-select"
									v-model="selectedAction.data.fromParts[1]"
									aria-label="Email from domain"
								>
									<option value="heygov.com">heygov.com</option>
									<option value="email.heygov.com">email.heygov.com</option>
								</select>
							</div>
						</div>

						<div class="form-group mb-3">
							<label class="form-label" for="action-email-to">To</label>

							<input
								type="email"
								class="form-control"
								id="action-email-to"
								required
								v-model="selectedAction.data.to"
								placeholder="example@heygov.com"
								aria-label="Email address"
							/>
						</div>

						<div class="form-group mb-3">
							<label class="form-label" for="action-email-subject">Subject</label>

							<input
								type="email"
								class="form-control"
								id="action-email-subject"
								required
								v-model="selectedAction.data.subject"
								placeholder="I'm reaching out about your car's extended warranty, please call me back"
								aria-label="Email subject"
							/>
						</div>

						<div class="form-group">
							<label class="form-label" for="action-email-body">Body</label>

							<textarea
								class="form-control"
								id="action-email-body"
								required
								v-model="selectedAction.data.body"
								placeholder="Hi there, I'm reaching out about your car's extended warranty. Please call me back at 555-555-5555. Thanks!"
								aria-label="Email body"
								rows="8"
							></textarea>
						</div>
					</div>
					<div v-else class="modal-body">
						<p>Cannot edit this type of action yet</p>
						<pre>{{ selectedAction }}</pre>
					</div>
					<div class="modal-footer justify-content-between">
						<button class="btn btn-sm" type="button" data-bs-dismiss="modal">Cancel</button>
						<button class="btn btn-sm btn-primary px-5">Save</button>
					</div>
				</form>
			</div>
		</div>
		<!-- END Modal - Edit workflow action -->

		<!-- Modal - Add workflow action -->
		<div class="modal fade" id="modal-action-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title my-0">
							Add action to workflow
						</h5>

						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row row-cols row-cols-2">
							<div v-for="(action, key) in actions" :key="key" class="col mb-3">
								<div
									class="border p-2 rounded-1 cursor-pointer"
									:class="`bg-${action.color}-50`"
									@click="addWorkflowAction(key)"
								>
									<h6>
										<font-awesome-icon :icon="['fas', action.icon]" class="me-1" />
										{{ action.name }}
									</h6>

									<p class="mb-0">{{ action.description }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- END Modal - Edit workflow action -->
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { Modal } from 'bootstrap'

import heyGovApi, { handleResponseError } from '@/api.js'

export default {
	name: 'AdminWorkflowsEdit',
	components: {},
	data() {
		return {
			states: {
				workflow: 'loading',
			},

			workflow: null,

			selectedAction: null,

			actions: {
				'send-email': {
					name: 'Send email',
					description: 'Send an email on behalf of HeyGov',
					color: 'success',
					icon: 'paper-plane',
					renderText(data) {
						return `Send an email to "${data.to}" with subject "${data.subject}"`
					},
				},
				'slack-msg': {
					name: 'Send Slack message',
					description: 'Send a message to a Slack channel',
					color: 'primary',
					icon: 'comment',
					renderText(data) {
						return `Send an email to "${data.to}" with subject "${data.subject}"`
					},
				},
			},
		}
	},
	mounted() {
		this.$modalActionSendEmail = new Modal(document.getElementById('modal-action-edit'), {
			keyboard: false,
			backdrop: 'static',
		})
	},
	created() {
		this.loadWorkflow()
	},
	computed: {
		...mapState(['apiUrl']),
	},
	methods: {
		loadWorkflow() {
			this.states.workflow = 'loading'

			heyGovApi.get(`super-secret-admin-endpoints/workflows/${this.$route.params.id}`).then(({ data }) => {
				this.workflow = data
				this.states.workflow = 'loaded'
			}, handleResponseError('Failed to load workflow ({error})'))
		},

		addWorkflowAction() {
			Vue.toasted.show('Not implemented yet')
		},
		editAction(action) {
			this.selectedAction = window.structuredClone(action)

			if (this.selectedAction.action === 'send-email') {
				this.selectedAction.data.fromParts = this.selectedAction.data.from.split('@')
			}

			this.$modalActionSendEmail.show()
		},
		saveSelectedAction() {
			Vue.toasted.show('Action saved, maybe')
		},

		tbi() {
			Vue.toasted.show('Hey don\t touch that')
		},
	},
	watch: {},
}
</script>
