<template>
	<div class="card">
		<div class="card-body">
			<form @submit.prevent="updateUserInfo">
				<div class="row mb-3">
					<div class="col-md-6">
						<div class="form-group mb-3">
							<label for="account-first-name" class="form-label">Name</label>

							<div class="row gx-2">
								<div class="col-12 col-lg-4">
									<div class="form-floating mb-3">
										<input
											type="text"
											class="form-control"
											id="account-first-name"
											v-model="person.first_name"
										/>
										<label for="account-first-name">First name</label>
									</div>
								</div>
								<div class="col-12 col-lg-4">
									<div class="form-floating mb-3">
										<input
											type="text"
											class="form-control"
											id="account-middle-name"
											v-model="person.middle_name"
										/>
										<label for="account-middle-name">Middle name</label>
									</div>
								</div>
								<div class="col-12 col-lg-4">
									<div class="form-floating mb-3">
										<input
											type="text"
											class="form-control"
											id="account-last-name"
											v-model="person.last_name"
										/>
										<label for="account-last-name">Last name</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-md-6">
						<div class="form-group mb-3">
							<label for="person-email" class="form-label">Email</label>
							<input
								type="email"
								class="form-control"
								id="person-email"
								autocomplete="email"
								required
								v-model="person.email"
							/>
						</div>
					</div>

					<div class="col-md-6">
						<div class="form-group mb-3">
							<label for="person-bio" class="form-label">Bio</label>
							<textarea
								class="form-control"
								id="person-bio"
								v-model="person.bio"
								rows="3"
								placeholder="Short bio or intro"
							></textarea>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group mb-2">
							<label for="person-dob" class="form-label">Date of birth</label>
							<input
								type="date"
								class="form-control"
								id="person-dob"
								autocomplete="dob"
								v-model="person.dob"
							/>
						</div>
					</div>

					<div class="col-md-6">
						<div class="form-group mb-3">
							<label for="person-phone" class="form-label">Phone</label>

							<input
								type="text"
								class="form-control"
								id="person-phone"
								autocomplete="tel"
								v-model="person.phone"
							/>
						</div>
					</div>

					<div class="col-md-6">
						<div class="form-group mb-3">
							<label for="person-address" class="form-label">Address</label>

							<gmap-autocomplete @place_changed="setAccountAddress" :select-first-on-enter="true">
								<template v-slot:default="slotProps">
									<input
										ref="input"
										id="account-address"
										:value="person.address"
										placeholder="Address"
										class="form-control"
										v-on="slotProps.listeners"
									/>
								</template>
							</gmap-autocomplete>
						</div>
					</div>

					<div class="col-md-6">
						<div class="form-group mb-3">
							<label for="person-start-of-week" class="form-label">Start of week</label>
							<select class="form-select" v-model="person.start_of_week">
								<option value="Sun">Sunday</option>
								<option value="Mon">Monday</option>
							</select>
						</div>
					</div>
				</div>

				<div class="row justify-content-between align-items-center">
					<div class="col">
						<router-link :to="`/heygov-admin/people/${person.id}`" class="btn btn-sm btn-outline-dark"
							>← Back</router-link
						>
					</div>
					<div class="col text-end">
						<button class="btn btn-primary">Update info</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'

import heyGovApi, { handleResponseError } from '@/api.js'

export default {
	name: 'PersonUpdate',
	components: {},
	props: {
		person: Object,
	},
	data() {
		return {
			pNumber: '',
			defaultCountry: 'US',
			//preferedCountries: ['US', 'CA'],
		}
	},
	created() {
		//this.preparePhoneNumber()
	},
	methods: {
		setAccountAddress(place) {
			this.person.address = place.formatted_address
		},
		setPhoneNumber(payload) {
			if (payload.isValid) {
				const phone = '+' + payload.countryCallingCode + ' ' + payload.phoneNumber

				if (this.person.phone !== phone) {
					this.person.phone = phone
				}
			}
		},

		updateUserInfo() {
			const name = [this.person.first_name, this.person.middle_name, this.person.last_name]
				.filter(Boolean)
				.join(' ')
				.trim()

			heyGovApi
				.put(`/super-secret-admin-endpoints/people/${this.person.id}`, {
					name, //todo-name remove when name parts are used everywhere
					first_name: this.person.first_name,
					middle_name: this.person.middle_name,
					last_name: this.person.last_name,
					email: this.person.email,
					bio: this.person.bio,
					dob: this.person.dob,
					phone: this.person.phone,
					address: this.person.address,
					start_of_week: this.person.start_of_week,
				})
				.then(() => {
					this.$router.replace(`/heygov-admin/people/${this.person.id}`)
					Vue.toasted.success(`Account info updated`)
				}, handleResponseError('Error updating account ({error})'))
		},
	},
}
</script>
