<template>
	<Line-chart :chart-data="data" :chart-options="options" :height="height" />
</template>

<script>
import 'chart.js/auto'
import { Line as LineChart } from 'vue-chartjs/legacy'

export default {
	props: {
		data: Object,
		options: Object,
		height: Number,
	},
	components: { LineChart },
}
</script>
