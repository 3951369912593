<template>
	<div class="card">
		<div class="card-body">
			<div class="card-table">
				<table class="table table-hover">
					<thead>
						<tr class="text-uppercase text-muted fw-normal">
							<td>ID</td>
							<td>Jurisdiction</td>
							<td>Platform</td>
							<td>Date</td>
							<td>Amount</td>
							<td>Fee</td>
							<td>Status</td>
							<td>Method</td>
							<td>Source</td>
							<td>Description</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="payment in personPayments" :key="payment.id">
							<td>{{ payment.id }}</td>
							<td>
								{{ payment.jurisdiction_name }}
							</td>
							<td>{{ payment.platform }}</td>
							<td>{{ payment.created_at | dateLocal }}</td>
							<td>
								{{ payment.amount | currency }}
							</td>
							<td>
								{{ payment.fee | currency }}
							</td>
							<td>
								<payment-status :payment="payment" :tooltip="true" />
							</td>
							<td>
								<payment-method
									v-if="payment.payment_method"
									:payment-method="JSON.parse(payment.payment_method)"
								/>
							</td>
							<td>
								<code>{{ payment.source }}</code>
							</td>
							<td>{{ payment.description }}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr v-if="states.payments === 'loading'">
							<td colspan="9" class="text-center py-3">
								<div class="spinner-border spinner-border-sm" role="state"></div>
							</td>
						</tr>
						<tr v-else-if="states.payments === 'loaded' && !personPayments.length">
							<td colspan="9" class="text-center py-3">
								<span class="text-muted fw-bolder">There are no payments yet 🤷</span>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import heyGovApi, { handleResponseError } from '@/api.js'

import PaymentStatus from '@/components/payments/PaymentStatus.vue'
import PaymentMethod from '@/components/payments/PaymentMethod.vue'

export default {
	name: 'AdminPersonPayments',
	components: { PaymentStatus, PaymentMethod },
	props: {
		person: Object,
	},
	data() {
		return {
			states: {
				payments: 'loading',
			},
			personPayments: [],
		}
	},
	created() {
		this.loadPayments()
	},
	methods: {
		loadPayments() {
			const params = { person_id: this.person.id }
			this.states.payments = 'loading'

			heyGovApi.get('super-secret-admin-endpoints/payments', { params }).then(
				data => {
					this.personPayments = data.data
					this.states.payments = 'loaded'
				},
				error => {
					handleResponseError('Error loading payments ({error})')(error)
					this.states.payments = 'error'
				}
			)
		},
	},
}
</script>
