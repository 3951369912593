<template>
	<div class="threads-page">
		<h1 class="text-center mb-4 mt-3">Create a HeyGov instance</h1>

		<form @submit.prevent="createHeyGov">
			<div class="card mb-4">
				<div class="card-body">
					<div class="row gx-3">
						<div class="col-2">
							<select class="form-control" v-model="missionControlMunicipalities.search_state">
								<option value="">Any state</option>
								<option v-for="(stateName, state) in usStates" :key="state" :value="state">{{
									stateName
								}}</option>
							</select>
						</div>
						<div class="col">
							<div class="form-group">
								<input
									type="search"
									class="form-control bg-warning-lighter"
									placeholder="Search in TownWeb MC"
									v-model="missionControlMunicipalities.search_query"
									@focus="onFocus"
									@keydown="onKey"
									v-debounce.250ms="searchMc"
									tabindex="0"
								/>
								<div
									v-if="missionControlMunicipalities.results.length"
									class="mcmunis-search-results rounded-1 shadow position-absolute bg-white"
								>
									<table class="table table-borderless mb-0">
										<thead>
											<tr>
												<th>Name</th>
												<th>Website</th>
												<th>County</th>
												<th>State</th>
												<th>Population</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(m, index) in missionControlMunicipalities.results"
												:key="`munis-from-search-${m.id}-${m.name}`"
												class="cursor-pointer"
												@click="selectMuni(m)"
												:class="{ highlight: index === highlight }"
												@mouseover="mouseOver(index)"
											>
												<td>{{ m.full_name }}</td>
												<td>
													<template v-if="m.website">{{ m.website }}</template>
													<small v-else class="text-neutral-400">-</small>
												</td>
												<td>{{ m.county }}</td>
												<td>{{ m.state }}</td>
												<td>{{ (m.population || 0).toLocaleString() }}</td>
											</tr>
										</tbody>
										<tfoot>
											<tr v-if="hasLimit === 1" class="text-center">
												<td colspan="4" class="py-3 fw-bolder">
													<a type="button" class="text-secondary" @click="showMoreMunis">
														Show more
													</a>
												</td>
											</tr>
										</tfoot>
									</table>
								</div>
							</div>
						</div>
					</div>

					<hr class="bg-primary-200 my-4" />

					<div class="row">
						<div class="col-6">
							<div class="form-group mb-3">
								<label class="form-label" for="town-type"
									>Municipality type <span class="text-danger">*</span></label
								>
								<select
									class="form-select form-select-sm"
									id="town-type"
									required
									v-model="jurisdiction.type"
								>
									<option value="association">Association</option>
									<option value="borough">Borough</option>
									<option value="city">City</option>
									<option value="county">County</option>
									<option value="district">District</option>
									<option value="town">Town</option>
									<option value="township">Township</option>
									<option value="village">Village</option>
									<option value="organization">Other organization</option>
								</select>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-name"
									>{{ jurisdiction.type || 'municipality' }} name
									<span class="text-danger">*</span></label
								>
								<input
									type="text"
									class="form-control form-control-sm"
									id="town-name"
									required
									v-model="jurisdiction.name"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-website"
									>Website <span class="text-danger">*</span></label
								>
								<input
									type="url"
									class="form-control form-control-sm"
									id="town-website"
									required
									v-model="jurisdiction.website"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-slug"
									>Unique ID (slug) <span class="text-danger">*</span></label
								>
								<input
									type="text"
									class="form-control form-control-sm"
									id="town-slug"
									required
									v-model="jurisdiction.slug"
									placeholder="city.com"
								/>
								<small class="form-text text-muted"
									>Unique ID for municipality, usually domain name without "http://"</small
								>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-email"
									>{{ jurisdiction.type || 'municipality' }} email
									<span class="text-danger">*</span></label
								>
								<input
									type="email"
									class="form-control form-control-sm"
									id="town-email"
									required
									v-model="jurisdiction.email"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-timezone">Timezone</label>
								<input
									type="text"
									class="form-control form-control-sm"
									id="town-timezone"
									readonly
									v-model="jurisdiction.timezone"
								/>
								<small class="form-text text-muted"
									>Timezone is updated automatically based on location.</small
								>
							</div>

							<div class="row">
								<div class="col-6 form-group mb-3">
									<label class="form-label" for="population"
										>{{ jurisdiction.type || 'municipality' }} Population</label
									>
									<input
										type="number"
										class="form-control form-control-sm"
										id="population"
										v-model="jurisdiction.population_count"
										placeholder="Population count"
									/>
								</div>
								<div class="col-6 form-group mb-3">
									<label class="form-label" for="households"
										>{{ jurisdiction.type || 'municipality' }} Households</label
									>
									<input
										type="number"
										class="form-control form-control-sm"
										id="households"
										v-model="jurisdiction.households_count"
										placeholder="Households count"
									/>
								</div>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group mb-3">
								<label class="form-label" for="jurisdiction-location"
									>Location on map <span class="text-danger">*</span></label
								>
								<gmap-autocomplete
									:select-first-on-enter="true"
									:options="autocompleteOptions"
									@place_changed="setLocation"
								>
									<template v-slot:default="slotProps">
										<input
											ref="input"
											id="jurisdiction-location"
											:value="jurisdiction.location.city"
											:placeholder="
												`${jurisdiction.type || 'Municipality'} location, search by name`
											"
											class="form-control form-control-sm"
											v-on="slotProps.listeners"
											required
										/>
									</template>
								</gmap-autocomplete>
							</div>

							<div class="form-group mb-3">
								<Gmap-Map
									style="width: 100%; height: 350px;"
									:zoom="jurisdiction.location.zoom"
									:center="jurisdiction.location"
									@zoom_changed="updateLocationZoom"
								>
								</Gmap-Map>
							</div>

							<div
								class="form-group mb-3 p-2 rounded-1"
								:class="jurisdiction.testmode ? 'bg-danger-lighter' : 'bg-success-lighter'"
							>
								<label class="form-label" for="j-testmode">🚨 Live or test?</label>

								<div class="form-check form-switch mb-1">
									<input
										class="form-check-input"
										type="checkbox"
										v-model="jurisdiction.testmode"
										id="j-testmode"
									/>
									<label class="form-check-label" :for="`j-testmode-${jurisdiction.id}`"
										>Testmode</label
									>
								</div>

								<span class="form-text">This option can't be changed later</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<h4>Features &amp; Billing</h4>
			<div class="card mb-4">
				<div class="card-body">
					<p>Enable the features this municipality will have. Can be changed later too.</p>

					<div class="row">
						<div class="col-3">
							<div
								v-for="(label, feature) in features"
								:key="`jurisdiction-feature-${feature}`"
								class="form-check form-switch"
							>
								<input
									class="form-check-input"
									type="checkbox"
									:id="`j-${feature}`"
									v-model="jurisdiction.features"
									:value="feature"
								/>
								<label class="form-check-label me-3" :for="`j-${feature}`">{{ label }}</label>
							</div>
						</div>
						<div class="col-3">
							<div v-if="jurisdiction.features.includes('issues')" class="form-group mb-3">
								<label class="form-label">Hey311 privacy</label>
								<select
									class="form-select form-select-sm"
									v-model="jurisdiction.features_options.issues_privacy"
								>
									<option value="public">Public - show in progress requests</option>
									<option value="resolved">Show resolved requests</option>
									<option value="private">Private - Show only own requests and stats</option>
								</select>
							</div>
						</div>
						<div class="col-5">
							<div class="form-group mb-3">
								<label class="form-label" for="heygov-plan">HeyGov Plan</label>

								<select
									class="form-select form-select-sm"
									id="heygov-plan"
									v-model="jurisdiction.pricing"
								>
									<option value="custom">Custom plan</option>
									<option value="townweb">TownWeb does billing</option>
									<option value="townweb-ods-lite">TownWeb ODS (LITE)</option>
									<option value="townweb-ods-full">TownWeb ODS (FULL)</option>
									<option value="clerk-minutes">ClerkMinutes</option>
									<option value="hey311">Free Hey311</option>
								</select>
								<span class="form-text">Customized pricing can be created later</span>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="heygov-stripe-customer">Stripe Customer</label>
								<select
									class="form-select form-select-sm"
									id="heygov-stripe-customer"
									v-model="jurisdiction.stripe_customer_id"
								>
									<option :value="null">- no Stripe customer, add later -</option>
									<option value="new">🆕 Create customer in Stripe</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<h4>Departments</h4>
			<p>
				ℹ️ A single department named "Administration" will be created. Others need to be added manually.
			</p>

			<div class="card mb-4">
				<div class="card-header bg-white">
					<div class="row align-items-center">
						<div class="col-auto">
							<label :for="`department-logo`" class="mb-0"
								><img
									:src="
										`https://eu.ui-avatars.com/api/?size=100&background=cccccc&name=Administration`
									"
									class="rounded shadow"
									width="55"
									height="55"
									alt="Administration"
							/></label>
						</div>
						<div class="col">
							<h5 class="my-1">Administration</h5>

							<label :for="`dept-email-notifications`" class="mb-0">{{ jurisdiction.email }}</label>
						</div>
						<div class="col-auto">
							<p class="mb-0">
								<small class="text-uppercase"><strong>Department members</strong></small>
							</p>
							<p class="mb-0 text-muted">1 member</p>
						</div>
					</div>
				</div>
				<div class="card-body p-0">
					<table class="table table-hover mb-0">
						<thead>
							<tr>
								<th>Add?</th>
								<th>Name</th>
								<th>Email</th>
								<th>Phone</th>
								<th>HeyGov Role</th>
							</tr>
						</thead>
						<tbody>
							<tr class="on-parent">
								<td>
									<input type="checkbox" v-model="selfadmin" />
								</td>
								<td>
									{{ auth.name }}
								</td>
								<td>{{ auth.email }}</td>
								<td></td>
								<td>
									<span class="badge" :class="[roles['ADMIN'].class]">{{ roles['ADMIN'].name }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<p class="text-center mb-0">
				<button class="btn btn-lg btn-primary px-6" :disabled="states.jurisdiction === 'saving'">
					Create HeyGov
				</button>
			</p>
		</form>
	</div>
</template>
<style lang="scss" scoped>
.mcmunis-search-results {
	z-index: 1000;
	min-width: 800px;

	.table {
		tr.highlight {
			td {
				background-color: #ecf2ff;
			}
		}
	}
}
</style>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { getGoogleMapsAPI } from 'gmap-vue'

import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'
import { googleMapsApi, usStates } from '@/lib/geo.js'

export default {
	name: 'JurisdictionCreate',
	components: {},
	data() {
		return {
			state: 'idle',
			states: {
				jurisdiction: 'idle',
			},
			selfadmin: false,
			jurisdiction: {
				name: '',
				slug: '',
				type: 'town',
				email: '',
				website: '',
				location: {
					place_id: '',
					lat: 0,
					lng: 0,
					zoom: 12,
					city: '',
					county: '',
					state: '',
					country: 'US',
				},
				timezone: '',
				pricing: 'custom',
				features: ['forms'],
				features_options: {
					issues_privacy: 'public',
				},
				stripe_customer_id: null,
				testmode: false,
			},

			// search
			missionControlMunicipalities: {
				search_query: '',
				search_state: '',
				results: [],
			},
			highlight: 0,
			hasLimit: 0,
			expandMuniList: 0,
			usStates,

			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['address_components', 'geometry', 'place_id', 'website'],
				types: ['(cities)'],
			},
		}
	},
	created() {
		window.addEventListener('click', e => {
			// close dropdown when clicked outside
			if (!this.$el.contains(e.target)) {
				this.missionControlMunicipalities.results = []
			}
		})
	},
	computed: {
		...mapState(['j', 'roles', 'features']),
		...mapGetters(['auth']),
		google: getGoogleMapsAPI,
	},
	methods: {
		setLocation(place) {
			this.jurisdiction.location.place_id = place.place_id
			this.jurisdiction.location.lat = place.geometry.location.lat()
			this.jurisdiction.location.lng = place.geometry.location.lng()
			this.jurisdiction.website ||= place.website

			place.address_components.forEach(component => {
				if (component.types.includes('administrative_area_level_1')) {
					this.jurisdiction.location.state = component.short_name
				} else if (component.types.includes('administrative_area_level_2')) {
					this.jurisdiction.location.county = component.short_name || component.long_name
				} else if (component.types.includes('country')) {
					this.jurisdiction.location.country = component.short_name
				} else if (
					component.types.includes('locality') ||
					(!this.jurisdiction.location.city && component.types.includes('administrative_area_level_3'))
				) {
					this.jurisdiction.location.city = component.short_name || component.long_name
				}
			})

			// get timezone for this location
			googleMapsApi(
				`timezone/json?location=${place.geometry.location.lat()},${place.geometry.location.lng()}&timestamp=${Math.floor(
					Date.now() / 1000
				)}`
			)
				.then(data => {
					if (data.status === 'OK' && data.timeZoneId) {
						this.jurisdiction.timezone = data.timeZoneId
					} else {
						console.log('timezone response', data)
						throw new Error('Error finding timezone for this location. See dev console for more info')
					}
				})
				.catch(error => {
					alert(error.message)
				})
		},
		updateLocationZoom(zoom) {
			this.jurisdiction.location.zoom = zoom
		},

		createHeyGov() {
			this.states.jurisdiction = 'saving'

			const params = {
				source: 'admin',
				selfadmin: this.selfadmin ? 1 : 0,
			}

			heyGovApi
				.post('jurisdictions', this.jurisdiction, { params })
				.then(({ data }) => {
					Vue.toasted.success('New HeyGov is created, invite some members')

					this.$store.commit('setAuthJurisdictions', data.jurisdictions)
					this.$store.commit('setCurrentJurisdiction', data.jurisdictions[data.jurisdictions.length - 1])

					this.$router.push('/heygov-admin/jurisdictions')

					setTimeout(() => {
						window.location.reload()
					}, 700)
				})
				.catch(handleResponseError('Failed to create HeyGov ({error})'))
				.finally(() => {
					this.states.jurisdiction = 'idle'
				})
		},
		updateSlug() {
			try {
				const url = new URL(this.jurisdiction.website)

				if (url && url.hostname && !this.jurisdiction.slug) {
					this.jurisdiction.slug = url.hostname.replace('www.', '')
				}
			} catch (err) {
				console.log('invalid url', err)
			}
		},
		searchMc() {
			if (this.missionControlMunicipalities.search_query.length > 3) {
				const mcApiUrl = new URL('https://mc2api.townweb.com/api/heygov/municipalities/search')

				mcApiUrl.searchParams.set('token', '1hbnPuxxKguJe7HlgIHhIBeWvoVGgJrh')
				mcApiUrl.searchParams.set('search', this.missionControlMunicipalities.search_query)
				mcApiUrl.searchParams.set('state', this.missionControlMunicipalities.search_state)

				fetch(mcApiUrl)
					.then(res => {
						if (res.ok) {
							return res.json()
						} else {
							throw new Error(`${res.status} ${res.statusText}`)
						}
					})
					.then(data => {
						this.missionControlMunicipalities.results = data

						if (this.expandMuniList === 0) {
							if (!this.missionControlMunicipalities.results.length) {
								Vue.toasted.show(
									`No municipalities found for "${this.missionControlMunicipalities.search_query}"`
								)
							} else if (this.missionControlMunicipalities.results.length > 15) {
								this.hasLimit = 1
								this.missionControlMunicipalities.results = this.missionControlMunicipalities.results.slice(
									0,
									15
								)
							} else {
								this.hasLimit = 0
							}
						}
					})
					.catch(error => {
						Vue.toasted.error(`Failed to search MC (${error.message})`)
					})
			}
		},
		showMoreMunis() {
			this.expandMuniList = 1
			this.searchMc()
			this.hasLimit = 0
		},
		onFocus($event) {
			// on focus, display the list of results again
			if (this.missionControlMunicipalities.search_query.length) {
				this.searchMc($event)
			}
		},
		onKey: function(event) {
			switch (event.keyCode) {
				case 13:
					this.missionControlMunicipalities.results.forEach((m, index) => {
						if (index === this.highlight) {
							this.selectMuni(m)
						}
					})
					break
				case 38:
					if (this.highlight === 0) {
						this.highlight = this.missionControlMunicipalities.results.length - 1
					} else {
						this.highlight--
					}
					break
				case 40:
					if (this.highlight === this.missionControlMunicipalities.results.length - 1) {
						this.highlight = 0
					} else {
						this.highlight++
					}
					break
			}
		},
		mouseOver(index) {
			this.highlight = index
		},

		selectMuni(muni) {
			if (muni.website) {
				this.jurisdiction.website = muni.website
			}

			this.jurisdiction.type = muni.type.toLowerCase()
			this.jurisdiction.name = muni.full_name
			this.jurisdiction.population_count = muni.population
			this.jurisdiction.email = muni.email
			this.jurisdiction.mc_municipality_id = muni.id

			if (muni.city && muni.state) {
				this.doAddressLocation(`${muni.city} ${muni.state}, US`)
			}

			this.missionControlMunicipalities.results = []
		},
		doAddressLocation(address) {
			const geocoder = new this.google.maps.Geocoder()

			geocoder.geocode({ address }, (results, status) => {
				if (status === 'OK') {
					this.setLocation(results[0])
				}
			})
		},
	},
	watch: {
		'jurisdiction.website'() {
			this.updateSlug()
		},
	},
}
</script>
