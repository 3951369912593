<template>
	<div class="card">
		<div class="card-body">
			<div class="card-table">
				<table class="table table-hover">
					<thead>
						<tr class="text-uppercase text-muted fw-normal">
							<td>ID</td>
							<td><small>Jurisdiction</small></td>
							<td>Issue</td>
							<td>Status</td>
							<td>Source</td>
							<td>Created</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="thread in personIssues" :key="thread.id">
							<td>{{ thread.id }}</td>
							<td>
								{{ thread.jurisdiction_name }}
							</td>
							<td class="text-truncate" style="max-width: 300px;">
								<div>
									<router-link :to="`/${thread.jurisdiction_slug}/threads/${thread.uuid}`">{{
										thread.title ||
											(thread.messages.length ? thread.messages[0].message : '(no title)')
									}}</router-link>
								</div>
							</td>
							<td>
								<span
									v-if="issueStatuses[thread.status]"
									class="badge"
									:class="[issueStatuses[thread.status].bgClass, issueStatuses[thread.status].class]"
								>
									{{ issueStatuses[thread.status].name }}
								</span>
								<code v-else>{{ thread.status }}</code>
							</td>
							<td>{{ thread.source }}</td>
							<td>{{ thread.created_at | dateLocal }}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr v-if="states.issues === 'loading'">
							<td colspan="9" class="text-center py-3">
								<div class="spinner-border spinner-border-sm" role="state"></div>
							</td>
						</tr>
						<tr v-else-if="states.issues === 'loaded' && !personIssues.length">
							<td colspan="9" class="text-center py-3">
								<span class="text-muted fw-bolder">There are no reported issues yet 🤷</span>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import heyGovApi from '@/api.js'

export default {
	name: 'AdminPersonIssues',
	props: {
		person: Object,
	},
	data() {
		return {
			states: {
				issues: 'loading',
			},
			personIssues: [],
			issueStatuses: {
				new: {
					name: 'Received',
					class: 'text-warning',
					bgClass: 'bg-warning-lighter',
				},
				working: {
					name: 'In Progress',
					class: 'text-info',
					bgClass: 'bg-info-lighter',
				},
				resolved: {
					name: 'Resolved',
					class: 'text-success',
					bgClass: 'bg-success-lighter',
				},
				closed: {
					name: 'Declined',
					class: 'text-danger',
					bgClass: 'bg-danger-lighter',
				},
			},
		}
	},
	created() {
		this.loadThreads()
	},
	methods: {
		loadThreads() {
			this.states.issues = 'loading'
			const params = { personId: this.person.id }

			heyGovApi.get('super-secret-admin-endpoints/threads', { params }).then(data => {
				this.personIssues = data.data
				this.states.issues = 'loaded'
			})
		},
	},
}
</script>
