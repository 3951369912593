<template>
	<div class="row justify-content-center">
		<div class="col-md-6">
			<div class="card">
				<div class="card-header">
					<h5 class="my-0">Create a password for this account</h5>
				</div>
				<div class="car-body m-3">
					<form @submit.prevent="createPassword">
						<div class="form-group mb-3">
							<label for="new-password" class="form-label">New password</label>

							<input
								:type="passwords.show ? 'text' : 'password'"
								class="form-control"
								id="new-password"
								autocomplete="new-password"
								v-model="passwords.passwordNew"
								required
								minlength="8"
							/>
						</div>

						<div class="form-group mb-3">
							<label for="confirm-password" class="form-label">Confirm password</label>
							<input
								:type="passwords.show ? 'text' : 'password'"
								class="form-control"
								id="confirm-password"
								v-model="passwords.passwordConfirm"
								required
								minlength="8"
							/>
						</div>

						<div class="form-group mb-3">
							<div class="form-check">
								<input
									class="form-check-input"
									type="checkbox"
									v-model="passwords.show"
									id="pass-input-type"
								/>
								<label class="form-check-label" for="pass-input-type"
									>{{ passwords.show ? 'Hide' : 'Show' }} password</label
								>
							</div>
						</div>

						<p class="card-text text-center">
							<button class="btn btn-primary mt-2">Create password</button>
						</p>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import heyGovApi from '@/api.js'
import Vue from 'vue'

export default {
	props: {
		person: Object,
	},
	data() {
		return {
			passwords: {
				passwordNew: '',
				passwordConfirm: '',
				show: false,
			},
		}
	},
	methods: {
		createPassword() {
			if (!this.passwords.passwordNew.length) {
				Vue.toasted.error('Please type a password')
			} else if (this.passwords.passwordNew && this.passwords.passwordNew.length < 8) {
				Vue.toasted.error('Password length should be at least 8 characters')
			} else if (!this.passwords.passwordConfirm) {
				Vue.toasted.error('Please repeat the password')
			} else if (this.passwords.passwordNew !== this.passwords.passwordConfirm) {
				Vue.toasted.error('Make sure the confirmation password matches the password')
			} else {
				heyGovApi
					.put(`/super-secret-admin-endpoints/people/${this.person.id}`, {
						password: this.passwords.passwordNew,
					})
					.then(() => {
						this.person.password = true
						Vue.toasted.success('Password is created')
						this.$router.replace(`/heygov-admin/people/${this.person.id}`)
					})
			}
		},
	},
}
</script>
