<template>
	<div class="admin-form-requests-page">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">Form Submissions</h2>
			</div>
		</div>

		<div class="bar-filters border rounded bg-white p-1 mb-2">
			<div class="row align-items-center justify-content-around g-2">
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.jurisdiction_id">
						<option value="">All jurisdiction</option>
						<option value="live">Real jurisdictions</option>
						<option
							v-for="jurisdiction in jurisdictions"
							:key="`${jurisdiction.id}-munipality`"
							:value="jurisdiction.id"
							>{{ jurisdiction.name }}</option
						>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.status">
						<option value="">Status</option>
						<option v-for="(status, index) in formRequestStatuses" :key="index" :value="index">{{
							status.name
						}}</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.from_platform">
						<option value="">Source</option>
						<option value="web">Web</option>
						<option value="android">Android</option>
						<option value="ios">iOS</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.date_range">
						<option value="30-days">Last 30 days</option>
						<option value="3-months">Last 3 months</option>
						<option value="12-months">Last 12 months</option>
						<option value="-30-days">Older than 30 days</option>
					</select>
				</div>
			</div>
		</div>

		<div v-if="states.stats === 'idle'" class="row align-items-center my-3">
			<div class="col">
				<stats-line-chart :data="statsChart" :options="statsChartOptions" :height="250"></stats-line-chart>
			</div>
			<div class="col-auto">
				<stats-pie-chart
					:data="jurisdictionsChart"
					:options="jurisdictionChartOptions"
					:height="220"
				></stats-pie-chart>
			</div>
		</div>
		<div v-else class="text-center bg-light rounded-1 py-5 my-3" style="min-height: 250px">
			<p>Generating fresh stats for you</p>
			<span class="spinner-border" role="status"></span>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>ID</th>
								<th>Jurisdiction</th>
								<th>Form</th>
								<th>Applicant</th>
								<th @click="sortTable('status', 'asc', sorting)" class="hover cursor-pointer">
									Status
									<small v-if="sorting.orderBy === 'status'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th
									@click="
										sortTable(
											$route.query.status === 'draft' ? 'created_at' : 'sent_at',
											'asc',
											sorting
										)
									"
									class="hover cursor-pointer"
								>
									Received
									<small v-if="sorting.orderBy === 'sent_at' || sorting.orderBy === 'created_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th>Platform</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="fr in formRequests" :key="`form-request-${fr.id}`">
								<td class="text-muted">
									<small>{{ fr.id }}</small>
								</td>
								<td>{{ jurisdictions.find(j => j.id === fr.jurisdiction_id).name }}</td>
								<td>{{ fr.form.name }}</td>
								<td>
									<router-link
										v-if="fr.person"
										:to="`/heygov-admin/people/${fr.person.id}/form-requests`"
										>{{ fr.person.shortNameOrEmail }}</router-link
									>
								</td>
								<td>
									<div class="badge" :class="[formRequestStatuses[fr.status].class]">
										{{ formRequestStatuses[fr.status].name }}
									</div>
								</td>
								<td>{{ (fr.status === 'draft' ? fr.sent_at : fr.created_at) | dateLocal }}</td>
								<td>
									<code>{{ fr.from_platform }}</code>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr v-if="states.formRequests === 'loading'">
								<td colspan="9" class="text-center py-3">
									<div class="spinner-border spinner-border-sm" role="state"></div>
								</td>
							</tr>
							<tr v-else-if="states.formRequests === 'idle' && !formRequests.length">
								<td colspan="9" class="text-center py-3">
									<span class="text-muted fw-bolder">There are no form requests yet 🤷</span>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- Pagination -->
				<div class="row align-items-center py-2">
					<div class="col">
						<span class="me-3 text-muted">Per page</span>
						<div class="d-inline-flex ">
							<select class="form-select form-select-sm" v-model="pag.limit">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
					<div v-if="pag.total" class="col" @dblclick="states.delete = true">
						Showing {{ pag.limit * (pag.page - 1) }}-{{ pag.limit * pag.page }} of {{ pag.total }}
					</div>
					<div v-if="filters.status === 'draft' || states.delete" class="col-auto">
						<button class="btn btn-sm btn-outline-danger" @click="deleteOldFormRequestsDrafts">
							🗑️ Delete submissions
						</button>
					</div>
					<div class="col-auto">
						<nav aria-label="Page navigation example" v-if="pag.pages > 1">
							<ul class="pagination my-0">
								<!-- TODO add these buttons when there are > 20 pages -->

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = 1">First</a>
								</li>

								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="{ active: p == pag.page }"
								>
									<a
										v-if="Math.abs(pag.page - p) < 5 || pag.page == pag.pages - 1 || p == 0"
										class="page-link"
										@click="pag.page = p"
									>
										{{ p }}
									</a>
								</li>

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = pag.pages">Last</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import heyGovApi from '@/api.js'
import Vue from 'vue'
import { formRequestStatuses } from '@/actions/forms'
import { updatePageUrl, sortTable } from '@/actions/tables'

import StatsLineChart from '@/components/StatsLineChart.vue'
import StatsPieChart from '@/components/StatsPieChart.vue'

import { format, parseISO } from 'date-fns'

import { chartsCommonData, jChart, jChartOptions } from '../../actions/charts'

export default {
	props: { jurisdictions: Array },
	components: { StatsLineChart, StatsPieChart },
	data() {
		return {
			formRequests: [],
			states: {
				formRequests: 'idle',
				stats: 'idle',
				delete: false,
			},
			formRequestStatuses,
			filters: {
				jurisdiction_id: this.$route.query.jurisdiction_id || 'live',
				status: this.$route.query.status || '',
				from_platform: this.$route.query.from_platform || '',
				date_range: this.$route.query.date_range || localStorage.getItem('admin-date-range') || '12-months',
			},
			sorting: {
				orderBy: localStorage.getItem('admin-form-requests-orderBy') || 'created_at',
				order: localStorage.getItem('admin-form-requests-order') || 'desc',
			},
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				limit: localStorage.getItem('admin-form-requests-per-page') || 50,
			},
			statsChart: {
				labels: [],
				datasets: [
					{
						label: 'Total received',
						data: [],
						borderColor: 'rgba(34, 166, 255, 0.5)',
						backgroundColor: '#eff9ff',
						pointBorderColor: 'rgba(34, 166, 255, 0.5)',
						pointBackgroundColor: 'rgba(34, 166, 255, 0.5)',
						...chartsCommonData,
					},
					{
						label: 'In progress',
						data: [],
						borderColor: 'rgba(252, 171, 28, 0.5)',
						backgroundColor: '#fff3e8',
						pointBorderColor: 'rgba(252, 171, 28, 0.5)',
						pointBackgroundColor: 'rgba(252, 171, 28, 0.5)',
						...chartsCommonData,
					},
					{
						label: 'Approved',
						data: [],
						borderColor: 'rgba(42, 152, 54, 0.5)',
						backgroundColor: '#eef6e9',
						pointBorderColor: 'rgba(42, 152, 54, 0.5)',
						pointBackgroundColor: 'rgba(42, 152, 54, 0.5)',
						...chartsCommonData,
					},
				],
			},
			statsChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						grid: {
							display: false,
						},
						min: 0,
					},
				},
			},
			jurisdictionsChart: {
				...jChart,
			},
			jurisdictionChartOptions: {
				...jChartOptions,
			},
		}
	},
	created() {
		this.loadFormRequests()
	},
	methods: {
		updatePageUrl,
		sortTable,
		loadFormRequests() {
			this.states.formRequests = 'loading'
			const params = {
				expand: 'person',
				...this.filters,
				...this.sorting,
				limit: this.pag.limit,
				page: this.pag.page,
			}

			heyGovApi.get('admin/form-requests', { params }).then(data => {
				this.formRequests = data.data
				this.states.formRequests = 'idle'
				this.pag.total = data.headers['x-total']
				this.pag.pages = Math.ceil(this.pag.total / this.pag.limit)
			})

			this.updatePageUrl(this.pag, this.filters, this.$router, 'form-requests-list')

			if (this.filters.status !== 'draft') {
				this.loadStats()
			}
		},
		loadStats() {
			this.states.stats = 'loading'
			this.statsChart.labels = []
			this.jurisdictionsChart.labels = []
			this.statsChart.datasets[0].data = []
			this.statsChart.datasets[1].data = []
			this.jurisdictionsChart.datasets[0].data = []
			const params = { ...this.filters }

			heyGovApi.get('admin/form-requests-stats', { params }).then(data => {
				this.stats = data.data

				if (this.filters.date_range.includes('months') || this.filters.date_range.startsWith('-')) {
					for (let m in this.stats.byMonth) {
						this.statsChart.labels.push(format(parseISO(`${m}-01`), 'MMM yyyy'))
						this.statsChart.datasets[0].data.push(this.stats.byMonth[m].total)
						this.statsChart.datasets[1].data.push(this.stats.byMonth[m].received)
						this.statsChart.datasets[2].data.push(this.stats.byMonth[m].approved)
					}
				} else {
					for (let c in this.stats.byDay) {
						this.statsChart.labels.push(format(parseISO(`${c}`), 'MMM dd'))
						this.statsChart.datasets[0].data.push(this.stats.byDay[c].total)
						this.statsChart.datasets[1].data.push(this.stats.byDay[c].received)
						this.statsChart.datasets[2].data.push(this.stats.byDay[c].approved)
					}
				}

				for (let j in this.stats.byJurisdiction) {
					this.jurisdictionsChart.datasets[0].data.push(this.stats.byJurisdiction[j].count)
					this.jurisdictionsChart.labels.push(
						this.jurisdictions.find(jurisdiction => jurisdiction.id == j).name.substring(0, 25)
					)
				}

				this.states.stats = 'idle'
			})
		},
		deleteOldFormRequestsDrafts() {
			if (confirm('Are you sure you want to delete old form requests drafts?')) {
				heyGovApi.get(`admin/cleanup-form-requests`, { params: this.filters }).then(({ data }) => {
					this.states.delete = false
					this.filters = {
						...this.filters,
						status: '',
						date_range: localStorage.getItem('admin-date-range') || '12-months',
					}

					Vue.toasted.show(`${data} form requests were deleted`)
				})
			}
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.formRequests = []
				this.loadFormRequests()

				if (!this.filters.date_range.startsWith('-')) {
					localStorage.setItem('admin-date-range', this.filters.date_range)
				}
			},
		},
		sorting: {
			deep: true,
			handler() {
				if (this.pag.page !== 1) {
					this.pag.page = 1
				} else {
					this.formRequests = []
					this.loadFormRequests()
				}
				localStorage.setItem('admin-form-requests-orderBy', this.sorting.orderBy)
				localStorage.setItem('admin-form-requests-order', this.sorting.order)
			},
		},
		'pag.page'() {
			this.formRequests = []
			this.loadFormRequests()
		},
		'pag.limit'() {
			this.formRequests = []
			this.loadFormRequests()
		},
	},
}
</script>
