<template>
	<div class="jurisdiction-page">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">
					HeyGovs <span class="badge bg-light text-dark">{{ pag.total }}</span>
				</h2>
			</div>
			<div class="col-auto">
				<router-link to="/heygov-admin/create-jurisdiction" class="btn btn-primary btn-sm btn-big-icon"
					><font-awesome-icon :icon="['fas', 'plus-circle']" /> New HeyGov</router-link
				>
			</div>
		</div>

		<div class="bar-filters border rounded bg-white p-1 mb-3">
			<div class="row align-items-center justify-content-around g-2">
				<div class="col-sm-6 col-md">
					<input
						class="form-control form-control-sm"
						type="search"
						placeholder="🔍 Search"
						v-model="filters.search"
					/>
				</div>
				<div class="col-sm-6 col-md">
					<select class="form-select form-select-sm" v-model="filters.feature">
						<option value="">Any features</option>
						<option value="clerkminutes">ClerkMinutes</option>
						<option value="issues">Hey311</option>
						<option value="forms">HeyLicense</option>
						<option value="venues">HeyReserve</option>
						<option value="payments">QuickPay</option>
						<option value="events">Events</option>
						<option value="meetings">Meetings</option>
					</select>
				</div>
				<div class="col-sm-6 col-md">
					<select class="form-select form-select-sm" v-model="filters.public">
						<option value="">Privacy - Any</option>
						<option value="1">Public</option>
						<option value="0">Not Public</option>
					</select>
				</div>
				<div class="col-sm-6 col-md">
					<select class="form-select form-select-sm" v-model="filters.livemode">
						<option value="">Mode - Live &amp; Test</option>
						<option value="1">Live</option>
						<option value="0">Test</option>
					</select>
				</div>
				<div class="col-sm-6 col-md">
					<select class="form-select form-select-sm" v-model="filters.type">
						<option value="">All muni types</option>
						<option value="city">City</option>
						<option value="town">Town</option>
						<option value="village">Village</option>
						<option value="district">District</option>
					</select>
				</div>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-body">
				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th class="hover cursor-pointer" @click="sortTable('name', 'asc')">
									Name
									<small v-if="sorting.orderBy === 'name'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th>Location</th>
								<th>Features</th>
								<th>Billing</th>
								<th class="hover cursor-pointer" @click="sortTable('event_app_open', 'desc')">
									<small>App opens</small>
									<small v-if="sorting.orderBy === 'event_app_open'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th class="hover cursor-pointer" @click="sortTable('event_widget_show', 'desc')">
									<small>Widget opens</small>
									<small v-if="sorting.orderBy === 'event_widget_show'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th class="hover cursor-pointer" @click="sortTable('created_at', 'desc')">
									Created
									<small v-if="sorting.orderBy === 'created_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="jurisdiction in jurisdictions"
								:key="`j-${jurisdiction.id}`"
								class="border-bottom on-parent"
								:class="{ 'bg-danger-lighter': !jurisdiction.livemode }"
							>
								<td>
									<img
										:src="`https://logo.clearbit.com/${jurisdiction.slug}`"
										:alt="jurisdiction.name"
										class="rounded-circle float-start me-2"
										@error="$event.target.classList.add('d-none')"
										height="40"
									/>
									<p class="mb-0">
										<router-link :to="`/heygov-admin/jurisdiction/${jurisdiction.slug}`">{{
											jurisdiction.name
										}}</router-link>

										<font-awesome-icon
											v-if="!jurisdiction.public"
											:icon="['fas', 'eye-slash']"
											class="ms-1 text-muted"
										/>
									</p>
									<p class="mb-0">
										<router-link :to="`/${jurisdiction.slug}`"
											><small class="text-muted">{{ jurisdiction.slug }}</small></router-link
										>
									</p>
								</td>
								<td>
									<p class="mb-1">
										<template
											v-if="
												!jurisdiction.location.city ||
													!jurisdiction.location.county ||
													!jurisdiction.location.state
											"
											>⚠️
										</template>
										<small>{{
											[
												jurisdiction.location.city,
												jurisdiction.location.county,
												jurisdiction.location.state,
											]
												.filter(Boolean)
												.join(', ')
										}}</small>
									</p>
									<p class="mb-0">
										<small class="text-muted">TZ: {{ jurisdiction.timezone }}</small>
									</p>
								</td>
								<td>
									<span
										v-for="feature in jurisdiction.features"
										:key="feature"
										class="badge badge-xs bg-info-lighter text-info me-1 mb-1"
									>
										{{ features[feature] }}
									</span>
								</td>
								<td>
									<img
										v-if="jurisdiction.pricing === 'townweb'"
										src="https://assets.website-files.com/5fd9ec20a182fdcd717d73c6/609c149075fd9aeb72cf78b7_Town_Web_Trademark-p-500.png"
										alt="TownWeb"
										width="90"
									/>
									<code v-else-if="jurisdiction.pricing">
										{{ jurisdiction.pricing }}
									</code>
									<i v-else class="text-neutral-400">no plan</i>
								</td>
								<td>
									<span class="text-muted">
										<span class="badge bg-success-lighter text-success fs-6">
											{{ (jurisdiction.event_app_open || 0).toLocaleString() }}
										</span>
									</span>
								</td>
								<td>
									<span class="text-muted">
										<span class="badge bg-info-lighter text-info">
											{{ (jurisdiction.event_widget_show || 0).toLocaleString() }}
										</span>
									</span>
								</td>
								<td>{{ jurisdiction.created_at | dateLocal }}</td>
							</tr>
						</tbody>
						<tfoot v-if="states.jurisdictions === 'loading'">
							<tr>
								<td colspan="7" class="text-center py-4">
									<span class="spinner-border" role="status"></span>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- Pagination -->
				<div class="row mt-4">
					<div class="col-auto">
						<span class="me-3 text-muted">Per page</span>
						<div class="d-inline-flex">
							<select class="form-select form-select-sm" v-model="pag.limit">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
					<div class="col-auto ms-auto">
						<nav aria-label="Jurisdiction pagination" v-if="pag.pages">
							<ul class="pagination mb-0">
								<!-- TODO add these buttons when there are > 20 pages -->
								<!-- 	<li class="page-item" :class="{ disabled: pag.page < 2 }">
									<a class="page-link" @click="pag.page -= 1">Previous</a>
								</li>
								<li class="page-item">
									<a class="page-link" @click="pag.page = 1">First</a>
								</li> -->

								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="{ active: p == pag.page }"
								>
									<a
										class="page-link three-dots"
										@click="pag.page = p"
										v-if="Math.abs(pag.page - p) < 3 || pag.page == pag.pages - 1 || p == 0"
									>
										{{ p }}
									</a>
								</li>

								<!-- 	<li class="page-item">
									<a class="page-link" @click="pag.page = pag.pages">Last</a>
								</li>
								<li class="page-item" :class="{ disabled: pag.page >= pag.pages }">
									<a class="page-link" @click="pag.page += 1">Next</a>
								</li> -->
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import heyGovApi from '@/api.js'
import { mapGetters, mapState } from 'vuex'
import { debounce } from 'vue-debounce'

export default {
	name: 'AdminJurisdictions',
	components: {},
	data() {
		return {
			states: {
				jurisdictions: 'loading',
			},
			jurisdictions: [],
			filters: {
				feature: this.$route.query.feature || '',
				type: this.$route.query.type || '',
				public: this.$route.query.public || '',
				livemode: this.$route.query.livemode || '',
				search: this.$route.query.search || '',
			},
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				limit: localStorage.getItem('admin-jurisdictions-per-page') || 10,
			},
			sorting: {
				orderBy: localStorage.getItem('admin-jurisdictions-order-by') || 'event_app_open',
				order: localStorage.getItem('admin-jurisdictions-order') || 'desc',
			},
		}
	},

	created() {
		this.loadJurisdictions()
	},
	computed: {
		...mapState(['features']),
		...mapGetters(['auth']),
		loadJurisdictionsDebounced() {
			return debounce(this.loadJurisdictions, 250)
		},
	},
	methods: {
		loadJurisdictions() {
			this.states.jurisdictions = 'loading'

			const params = {
				...this.filters,
				...this.sorting,
				limit: this.pag.limit,
				page: this.pag.page,
			}

			heyGovApi.get('jurisdictions', { params }).then(({ data, headers }) => {
				this.pag.total = headers['x-total']
				this.pag.pages = Math.ceil(this.pag.total / this.pag.limit)
				this.jurisdictions = data

				this.states.jurisdictions = 'idle'
			})

			this.updatePageUrl()
		},
		updatePageUrl() {
			let query = {}

			for (const filter in this.filters) {
				if (this.filters[filter]) {
					query[filter] = this.filters[filter]
				}
			}

			if (this.pag.page && this.pag.page != 1) {
				query.page = this.pag.page
			}

			this.$router.replace({ path: `/heygov-admin/jurisdictions`, query }).catch(() => {})
		},

		sortTable(orderBy, defaultOrder) {
			if (this.sorting.orderBy === orderBy) {
				// if the same column is clicked, reverse the sort order
				this.sorting.order = this.sorting.order === 'asc' ? 'desc' : 'asc'
			} else {
				// if a new column is clicked, start with the default order
				this.sorting.order = defaultOrder
			}

			this.sorting.orderBy = orderBy
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.jurisdictions = []
				this.loadJurisdictionsDebounced()
			},
		},
		sorting: {
			deep: true,
			handler() {
				if (this.pag.page !== 1) {
					// when sorting is changed, reset the page to 1
					// this triggers the loadJurisdictions()
					this.pag.page = 1
				} else {
					// otherwise reload data now
					this.jurisdictions = []
					this.loadJurisdictions()
				}

				localStorage.setItem('admin-jurisdictions-order', this.sorting.order)
				localStorage.setItem('admin-jurisdictions-order-by', this.sorting.orderBy)
			},
		},
		'pag.page'() {
			this.jurisdictions = []
			this.loadJurisdictions()
		},
		'pag.limit'() {
			this.jurisdictions = []
			this.loadJurisdictions()
			localStorage.setItem('admin-jurisdictions-per-page', this.pag.limit)
		},
	},
}
</script>
