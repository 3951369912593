<template>
	<div class="page-admin-big-stats">
		<div class="row">
			<div class="col-3">
				<div class="bg-neutral-50 rounded p-3">
					<div class="row mb-3">
						<div class="col">
							<h3 class="display-6 text-neutral-500">Municipalities</h3>
							<h5 class="display-2 my-0" ref="statJurisdictions">0</h5>
						</div>
						<div class="col">
							<h3 class="display-6">Residents</h3>
							<h5 class="display-2 my-0" ref="statPeople">0</h5>
						</div>
					</div>

					<div
						v-for="j in jurisdictions.slice(0, 8)"
						:key="j.id"
						class="row align-items-center py-3 gx-3 border-bottom"
					>
						<div class="col-auto">
							<img
								:src="`https://logo.clearbit.com/${j.slug}`"
								class="rounded-circle bg-white border"
								width="70"
								height="70"
								:alt="j.name"
								@error="e => (e.target.src = 'https://via.placeholder.com/70')"
							/>
						</div>
						<div class="col">
							<h1 class="mt-0 mb-2 display-6">
								{{ j.name }} <small class="text-neutral-400">{{ j.location.state }}</small>
							</h1>

							<p v-if="j.population_count" class="lead mb-2">
								Population: <strong>{{ j.population_count.toLocaleString() }}</strong>
							</p>
							<p class="mb-0">
								<span
									v-for="feature in j.features.filter(f => !['events', 'meetings'].includes(f))"
									:key="feature"
									class="me-3"
								>
									<img
										v-if="heyGovFeatures[feature]"
										:src="heyGovFeatures[feature].logo"
										width="27"
										height="25"
										:title="heyGovFeatures[feature].name"
									/><span v-else>{{ feature }}</span>
								</span>
							</p>
						</div>
					</div>

					<p class="mb-0 mt-3">Meagan is signing more right now</p>
				</div>
			</div>

			<div class="col">
				<div class="bg-neutral-50 rounded p-3 mb-4">
					<div class="row text-center">
						<div class="col">
							<h3 class="display-6 mt-2 mb-4 text-success-400">
								{{ stats.payments.toLocaleString() }} Payments
							</h3>
							<h5 class="display-3 my-0" ref="statPaymentsAmount">0</h5>
						</div>

						<div class="col">
							<h3 class="display-6 mt-2 mb-4 text-primary-200">AI Minutes</h3>
							<h5 class="display-3 my-0" ref="statMinutesGenerated">0</h5>
						</div>

						<div class="col">
							<h3 class="display-6 mt-2 mb-4 text-warning-400">Reservations</h3>
							<h5 class="display-3 my-0" ref="statVenueBookings">0</h5>
						</div>

						<div class="col">
							<h3 class="display-6 mt-2 mb-4 text-primary-400">Form submissions</h3>
							<h5 class="display-3 my-0" ref="statFormRequests">0</h5>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-9">
						<canvas ref="globe" style="width: 1300px; height: 800px;" width="2600" height="1600"></canvas>
					</div>
					<div class="col">
						[realtime alerts]
					</div>
				</div>
			</div>
		</div>

		<audio src="https://edge.heygov.com/app-assets/audio-money-1.mp3" ref="audio-money-1"></audio>
		<audio src="https://edge.heygov.com/app-assets/audio-money-2.mp3" ref="audio-money-2"></audio>
		<audio src="https://edge.heygov.com/app-assets/audio-money-3.mp3" ref="audio-money-3"></audio>
		<audio src="https://edge.heygov.com/app-assets/audio-money-4.mp3" ref="audio-money-4"></audio>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { CountUp } from 'countup.js'
import Vue from 'vue'
import createGlobe from 'cobe'
import VueConfetti from 'vue-confetti'
import { debounce } from 'vue-debounce'
import { sample } from 'lodash-es'

import heyGovApi, { handleResponseError } from '@/api.js'
import { heyGovFeatures, numberToCurrency } from '@/utils.js'

Vue.use(VueConfetti)

export default {
	name: 'AdminDashboard',
	components: {},
	data() {
		return {
			heyGovFeatures,
			states: {
				stats: 'loading',
			},

			jurisdictions: [],

			// stats data
			statsNew: false,
			statsInterval: null,
			stats: {
				jurisdictions: 0,
				people: 0,
				payments: 0,
				paymentsAmount: 0,
				formRequests: 0,
				venueBookings: 0,
				threads: 0,
				meetings: 0,
				minutesGenerated: 0,
			},
			counters: {
				jurisdictions: null,
				people: null,
				payments: null,
				paymentsAmount: null,
				formRequests: null,
				venueBookings: null,
				minutesGenerated: null,
			},

			globe: null,
			globePhi: 0,
			globeMarkers: [],

			audioMoney: [],
		}
	},

	created() {
		this.loadJurisdictions()

		this.loadLifetimeStats()
		this.statsInterval = setInterval(this.loadLifetimeStats, 35000)
	},
	computed: {
		...mapState(['apiUrl', 'features']),
	},
	mounted() {
		this.counters.people = new CountUp(this.$refs.statPeople, 0)
		this.counters.people.start()

		this.counters.jurisdictions = new CountUp(this.$refs.statJurisdictions, 0)
		this.counters.jurisdictions.start()

		this.counters.paymentsAmount = new CountUp(this.$refs.statPaymentsAmount, 0, {
			decimalPlaces: 2,
			prefix: '$',
		})
		this.counters.paymentsAmount.start()

		this.counters.venueBookings = new CountUp(this.$refs.statVenueBookings, 0)
		this.counters.venueBookings.start()

		this.counters.formRequests = new CountUp(this.$refs.statFormRequests, 0)
		this.counters.formRequests.start()

		this.counters.minutesGenerated = new CountUp(this.$refs.statMinutesGenerated, 0)
		this.counters.minutesGenerated.start()

		// globe
		const width = 2600
		const height = 1600

		this.globe = createGlobe(this.$refs.globe, {
			devicePixelRatio: 2,
			width,
			height,
			phi: 6.25,
			theta: 0.6688,
			dark: 0,
			diffuse: 1.2,
			scale: 2,
			mapSamples: 13000,
			mapBrightness: 6,
			baseColor: [0.8, 0.8, 0.8],
			markerColor: [0.988, 0.671, 0.11],
			glowColor: [1, 1, 1],
			offset: [0, 0],
			markers: [],
			onRender: state => {
				// Called on every animation frame.
				// `state` will be an empty object, return updated params.
				state.phi = this.globePhi
				this.globePhi += 0.00005

				if (this.globePhi > 0.5) {
					this.globePhi = 0
				}

				state.markers = this.globeMarkers
			},
		})

		this.audioMoney.push(
			this.$refs['audio-money-1'],
			this.$refs['audio-money-2'],
			this.$refs['audio-money-3'],
			this.$refs['audio-money-4']
		)
	},
	methods: {
		loadLifetimeStats() {
			heyGovApi('super-secret-admin-endpoints/heygov-lifetime-totals').then(({ data }) => {
				this.stats = data

				setTimeout(() => {
					this.statsNew = true
				}, 300)
			}, handleResponseError('Failed to load lifetime stats {error}'))
		},

		loadJurisdictions() {
			heyGovApi('https://heygov-api-main-uez7jeil7q-uc.a.run.app/jurisdictions?limit=800').then(({ data }) => {
				this.globeMarkers = data.map(jurisdiction => {
					let size = 0.02

					if (jurisdiction.population_count > 40000) {
						size = 0.04
					} else if (jurisdiction.population_count > 20000) {
						size = 0.03
					}

					return {
						location: [jurisdiction.location.lat, jurisdiction.location.lng],
						size,
					}
				})

				this.jurisdictions.push(...data)
			}, handleResponseError('Failed to load jurisdictions {error}'))
		},

		shortNumber(number, decimals = 1, options = {}) {
			let suffix = ''

			if (number > 1000000) {
				number = number / 1000000
				suffix = 'm'
			} else if (number > 1000) {
				number = number / 1000
				suffix = 'k'
			}

			return (
				number.toLocaleString('default', {
					...options,
					minimumFractionDigits: 0,
					maximumFractionDigits: decimals,
				}) + suffix
			)
		},

		stopConfetti: debounce(function() {
			this.$confetti.stop()
		}, 3000),
	},
	watch: {
		'stats.people'(val) {
			this.counters.people.update(val)
		},
		'stats.jurisdictions'(val) {
			this.counters.jurisdictions.update(val)
		},
		'stats.paymentsAmount'(val, prev) {
			this.counters.paymentsAmount.update(val)

			if (this.statsNew) {
				Vue.toasted.success(`New payments: ${numberToCurrency(val - prev)}`)

				sample(this.audioMoney).play()

				this.$confetti.start({
					particles: [
						{
							type: 'image',
							url: 'https://files.heygov.com/assets/icon-heygovpay.png',
							size: 20,
						},
					],
				})

				this.stopConfetti()
			}
		},
		'stats.venueBookings'(val) {
			this.counters.venueBookings.update(val)
		},
		'stats.formRequests'(val, prev) {
			this.counters.formRequests.update(val)

			if (this.statsNew) {
				Vue.toasted.info(`${val - prev} new form submissions`)

				this.$confetti.start({
					particles: [
						{
							type: 'image',
							url: 'https://files.heygov.com/assets/icon-heylicense.png',
							size: 25,
							dropRate: 5,
						},
					],
				})

				this.stopConfetti()
			}
		},
		'stats.minutesGenerated'(val) {
			this.counters.minutesGenerated.update(val)

			if (this.statsNew) {
				this.$confetti.start({
					particles: [
						{
							type: 'image',
							url: 'https://edge.heygov.com/app-assets/feature-clerkminutes.png',
							size: 25,
							dropRate: 5,
						},
					],
				})

				this.stopConfetti()
			}
		},
	},
	beforeDestroy() {
		this.globe.destroy()
		clearInterval(this.statsInterval)
	},
}
</script>
