<template>
	<div class="card">
		<div class="card-body">
			<div class="card-table">
				<table class="table table-hover">
					<thead>
						<tr class="text-uppercase text-muted fw-normal">
							<td>ID</td>
							<td>Jurisdiction</td>
							<td>Venue</td>
							<td>Status</td>
							<td>Price + Deposit</td>
							<td>Booking time</td>
						</tr>
					</thead>
					<tbody>
						<router-link
							v-for="booking in venueBookings"
							:key="booking.id"
							class="on-parent cursor-pointer"
							:to="`/${booking.jurisdiction_slug}/venues/${booking.venue.slug}/bookings/${booking.uuid}`"
							tag="tr"
						>
							<td>{{ booking.id }}</td>
							<td>
								{{ booking.jurisdiction_name }}
							</td>
							<td>
								{{ booking.venue.name }}
							</td>
							<td>
								<venue-booking-status :venueBooking="booking" />
							</td>
							<td>{{ booking.price | currency }} + {{ booking.deposit | currency }}</td>
							<td>
								{{
									new Date(booking.starts_at).toLocaleString('en-US', {
										dateStyle: 'short',
										timeStyle: 'short',
									})
								}}
							</td>
						</router-link>
					</tbody>
					<tfoot>
						<tr v-if="states.bookings === 'loading'">
							<td colspan="9" class="text-center py-3">
								<div class="spinner-border spinner-border-sm" role="state"></div>
							</td>
						</tr>
						<tr v-else-if="states.bookings === 'loaded' && !venueBookings.length">
							<td colspan="9" class="text-center py-3">
								<span class="text-muted fw-bolder">There are no venue bookings yet 🤷</span>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import heyGovApi, { handleResponseError } from '@/api.js'
import { venueBookingStatuses } from '@/actions/venues.js'

import VenueBookingStatus from '@/components/venues/VenueBookingStatus.vue'

export default {
	name: 'PersonBookings',
	components: { VenueBookingStatus },
	props: {
		person: Object,
	},
	data() {
		return {
			venueBookings: [],
			states: {
				bookings: 'loading',
			},
			venueBookingStatuses,
		}
	},
	created() {
		this.loadBookings()
	},
	methods: {
		loadBookings() {
			const params = { person_id: this.person.id }
			this.states.bookings = 'loading'

			heyGovApi.get('super-secret-admin-endpoints/venue-bookings', { params }).then(data => {
				this.venueBookings = data.data
				this.states.bookings = 'loaded'
			})
			error => {
				handleResponseError('Error loading venue bookings ({error})')(error)
				this.states.bookings = 'error'
			}
		},
	},
}
</script>
