<template>
	<div class="bookings-page">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">Venue reservations</h2>
			</div>
		</div>

		<div class="bar-filters border rounded bg-white p-1 mb-2">
			<div class="row align-items-center justify-content-around g-2">
				<!-- <div class="col">
					<input class="form-control" type="search" placeholder="🔍 Search" v-model="filters.search" />
				</div> -->
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.jurisdiction_id">
						<option value="">All jurisdiction</option>
						<option value="live">Real jurisdictions</option>
						<option
							v-for="jurisdiction in jurisdictions"
							:key="`${jurisdiction.id}-munipality`"
							:value="jurisdiction.id"
							>{{ jurisdiction.name }}</option
						>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.status">
						<option value="">Status</option>
						<option value="draft">Draft</option>
						<option value="pending">Pending</option>
						<option value="approved">Approved</option>
						<option value="progress">In progress</option>
						<option value="ended">Checked out</option>
						<option value="completed">Completed</option>
						<option value="declined">Declined</option>
						<option value="canceled">Canceled</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.from_platform">
						<option value="">Source</option>
						<option value="web">Web</option>
						<option value="android">Android</option>
						<option value="ios">iOS</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.date_range">
						<option value="30-days">Last 30 days</option>
						<option value="3-months">Last 3 months</option>
						<option value="12-months">Last 12 months</option>
						<option value="-30-days">Older than 30 days</option>
					</select>
				</div>
			</div>
		</div>
		<div v-if="states.stats === 'idle'" class="row align-items-center my-3">
			<div class="col">
				<stats-line-chart :data="statsChart" :options="statsChartOptions" :height="250"></stats-line-chart>
			</div>
			<div class="col-auto">
				<stats-pie-chart
					:data="jurisdictionsChart"
					:options="jurisdictionChartOptions"
					:height="220"
				></stats-pie-chart>
			</div>
		</div>
		<div v-else class="text-center bg-light rounded-1 py-5 my-3" style="min-height: 250px">
			<p>Generating fresh stats for you</p>
			<span class="spinner-border" role="status"></span>
		</div>
		<div class="card rounded-1">
			<div class="card-body">
				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>ID</th>
								<th>Jurisdiction</th>
								<th>Venue</th>
								<th @click="sortTable('price', 'desc')" class="hover cursor-pointer">
									Price + Deposit
									<small v-if="sorting.orderBy === 'price'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th>Status</th>
								<th>Person</th>
								<th @click="sortTable('starts_at', 'desc')" class="hover cursor-pointer">
									Booking time
									<small v-if="sorting.orderBy === 'starts_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th
									@click="
										sortTable($route.query.status === 'draft' ? 'created_at' : 'booked_at', 'desc')
									"
									class="hover cursor-pointer"
								>
									Booked on
									<small v-if="sorting.orderBy === 'created_at' || sorting.orderBy === 'booked_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th>Platform</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="booking in bookings" :key="`heygov-admin-${booking.id}`">
								<td>
									<small class="text-muted">{{ booking.id }}</small>
								</td>
								<td>
									{{ jurisdictions.find(j => j.id === booking.jurisdiction_id).name }}
								</td>
								<td>{{ booking.venue.name }}</td>
								<td>{{ booking.price | currency }} + {{ booking.deposit | currency }}</td>
								<td>
									<venue-booking-status
										:venueBooking="booking"
										:tooltip="true"
									></venue-booking-status>
								</td>
								<td>
									<router-link
										v-if="booking.person"
										:to="`/heygov-admin/people/${booking.person.id}/venue-bookings`"
										>{{ booking.person.shortNameOrEmail }}</router-link
									>
								</td>
								<td>
									<venue-booking-datetime :venueBooking="booking" :timezones="true" />
								</td>
								<td>
									{{ booking.booked_at || booking.created_at | dateLocal }}
								</td>
								<td>
									{{ ['ios', 'android'].includes(booking.from_platform) ? '📱' : '🖥' }}
									<code>{{ booking.from_platform }}</code>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr v-if="states.bookings === 'loading'">
								<td colspan="9" class="text-center py-3">
									<div class="spinner-border spinner-border-sm" role="state"></div>
								</td>
							</tr>
							<tr v-else-if="states.bookings === 'idle' && !bookings.length">
								<td colspan="9" class="text-center py-3">
									<span class="text-muted fw-bolder">There are no venue bookings yet 🤷</span>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- Pagination -->
				<div class="row align-items-center">
					<div class="col">
						<span class="me-3 text-muted">Per page</span>
						<div class="d-inline-flex ">
							<select class="form-select form-select-sm" v-model="pag.limit">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
					<div v-if="pag.total" class="col" @dblclick="states.delete = true">
						Showing {{ pag.limit * (pag.page - 1) }}-{{ pag.limit * pag.page }} of {{ pag.total }}
					</div>
					<div v-if="filters.status === 'draft' || states.delete" class="col-auto">
						<button class="btn btn-sm btn-outline-danger" @click="deleteOldBookingsDrafts">
							🗑️ Delete bookings
						</button>
					</div>
					<div v-if="pag.pages > 1" class="col-auto">
						<nav aria-label="Page navigation example">
							<ul class="pagination my-0">
								<!-- TODO add these buttons when there are > 20 pages -->

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = 1">First</a>
								</li>

								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="{ active: p == pag.page }"
								>
									<a
										v-if="Math.abs(pag.page - p) < 5 || pag.page == pag.pages - 1 || p == 0"
										class="page-link"
										@click="pag.page = p"
									>
										{{ p }}
									</a>
								</li>

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = pag.pages">Last</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import heyGovApi from '@/api.js'
import Vue from 'vue'
import { format, parseISO } from 'date-fns'

import { chartsCommonData } from '../../actions/charts'

import StatsLineChart from '@/components/StatsLineChart.vue'
import StatsPieChart from '@/components/StatsPieChart.vue'
import VenueBookingStatus from '@/components/venues/VenueBookingStatus.vue'
import VenueBookingDatetime from '@/components/venues/VenueBookingDatetime.vue'

export default {
	components: { VenueBookingStatus, VenueBookingDatetime, StatsPieChart, StatsLineChart },
	props: { jurisdictions: Array },
	data() {
		return {
			bookings: [],
			stats: [],
			states: {
				bookings: 'idle',
				stats: 'idle',
				delete: false,
			},
			filters: {
				jurisdiction_id: this.$route.query.jurisdiction_id || 'live',
				status: this.$route.query.status || '',
				from_platform: this.$route.query.from_platform || '',
				date_range: this.$route.query.date_range || localStorage.getItem('admin-date-range') || '12-months',
			},
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				limit: localStorage.getItem('admin-bookings-per-page') || 25,
			},
			sorting: {
				orderBy: localStorage.getItem('admin-bookings-orderBy') || 'starts_at',
				order: localStorage.getItem('admin-bookings-order') || 'desc',
			},
			statsChart: {
				labels: [],
				datasets: [
					{
						label: 'Pending',
						data: [],
						borderColor: '#fcab1c',
						backgroundColor: '#fdcd77',
						pointBorderColor: '#fcab1c',
						pointBackgroundColor: '#fcab1c',
						...chartsCommonData,
					},
					{
						label: 'Confirmed',
						data: [],
						borderColor: '#2A9836',
						backgroundColor: '#8dd384',
						pointBorderColor: '#2A9836',
						pointBackgroundColor: '#2A9836',
						...chartsCommonData,
					},
					{
						label: 'Checked Out',
						data: [],
						borderColor: 'rgba(253,205,119,0.5)',
						backgroundColor: '#fff7e1',
						pointBorderColor: 'rgba(253,205,119,0.5)',
						pointBackgroundColor: 'rgba(253, 205, 119, 0.5)',
						...chartsCommonData,
					},
					{
						label: 'Completed',
						data: [],
						borderColor: '#7879F1',
						backgroundColor: '#B9C4FF',
						pointBorderColor: '#7879F1',
						pointBackgroundColor: '#7879F1',
						...chartsCommonData,
					},
				],
			},
			statsChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						grid: {
							display: false,
						},
						min: 0,
					},
				},
			},
			jurisdictionsChart: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [
							'rgb(109, 249, 177)',
							'rgb(52, 55, 226)',
							'rgb(21, 129, 237)',
							'rgb(46, 153, 160)',
							'rgb(4, 201, 109)',
							'rgb(255, 168, 30)',
							'rgb(46, 79, 201)',
							'rgb(255, 50, 64)',
							'rgb(62, 37, 221)',
							'rgb(82, 229, 143)',
							'rgb(198, 30, 216)',
							'rgb(43, 175, 70)',
							'rgb(206, 255, 102)',
							'rgb(88, 132, 0)',
							'rgb(142, 11, 22)',
							'rgb(203, 97, 244)',
						],
						borderWidth: 1,
						hoverOffset: 2,
					},
				],
			},
			jurisdictionChartOptions: {
				cutout: 50,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
					title: {
						display: true,
						text: 'by Jurisdiction',
						font: 'Agradir',
					},
				},
			},
		}
	},
	created() {
		this.loadBookings()
	},
	methods: {
		loadBookings() {
			this.states.bookings = 'loading'

			const params = { ...this.filters, ...this.sorting, limit: this.pag.limit, page: this.pag.page }

			heyGovApi.get('admin/venue-bookings?expand=person', { params }).then(data => {
				this.bookings = data.data
				this.pag.total = data.headers['x-total']
				this.pag.pages = Math.ceil(this.pag.total / this.pag.limit)
				this.states.bookings = 'idle'
			})

			if (this.filters.status !== 'draft') {
				this.loadStats()
			}

			this.updatePageUrl()
		},
		loadStats() {
			this.states.stats = 'loading'

			this.statsChart.labels = []
			this.jurisdictionsChart.labels = []
			this.statsChart.datasets[0].data = []
			this.statsChart.datasets[1].data = []
			this.statsChart.datasets[2].data = []
			this.statsChart.datasets[3].data = []
			this.jurisdictionsChart.datasets[0].data = []

			const params = this.filters
			heyGovApi.get('admin/venue-bookings-stats', { params }).then(data => {
				this.stats = data.data
				if (this.filters.date_range.includes('months')) {
					for (let m in this.stats.byMonth) {
						this.statsChart.labels.push(format(parseISO(`${m}-01`), 'MMM yyyy'))
						this.statsChart.datasets[0].data.push(this.stats.byMonth[m].pending)
						this.statsChart.datasets[1].data.push(this.stats.byMonth[m].approved)
						this.statsChart.datasets[2].data.push(this.stats.byMonth[m].ended)
						this.statsChart.datasets[3].data.push(this.stats.byMonth[m].completed)
					}
				} else {
					for (let c in this.stats.byDay) {
						this.statsChart.labels.push(format(parseISO(`${c}`), 'MMM dd'))
						this.statsChart.datasets[0].data.push(this.stats.byDay[c].pending)
						this.statsChart.datasets[1].data.push(this.stats.byDay[c].approved)
						this.statsChart.datasets[2].data.push(this.stats.byDay[c].ended)
						this.statsChart.datasets[3].data.push(this.stats.byDay[c].completed)
					}
				}

				for (let j in this.stats.byJurisdiction) {
					this.jurisdictionsChart.datasets[0].data.push(this.stats.byJurisdiction[j].count)
					this.jurisdictionsChart.labels.push(
						this.jurisdictions.find(jurisdiction => jurisdiction.id == j).name.substring(0, 25)
					)
				}
				this.states.stats = 'idle'
			})
		},
		updatePageUrl() {
			let query = {}
			for (const filter in this.filters) {
				if (this.filters[filter]) {
					query[filter] = this.filters[filter]
				}
			}
			if (this.pag.page && this.pag.page != 1) {
				query.page = this.pag.page
			}

			this.$router.replace({ path: `/heygov-admin/bookings-list`, query }).catch(() => {})
		},
		sortTable(orderBy, defaultOrder) {
			if (this.sorting.orderBy === orderBy) {
				// if the same column is clicked, reverse the sort order
				this.sorting.order = this.sorting.order === 'asc' ? 'desc' : 'asc'
			} else {
				// if a new column is clicked, start with the default order
				this.sorting.order = defaultOrder
			}

			this.sorting.orderBy = orderBy
		},
		deleteOldBookingsDrafts() {
			if (confirm('For sure delete these venue bookings?')) {
				heyGovApi.get(`admin/cleanup-venue-bookings`, { params: this.filters }).then(({ data }) => {
					this.states.delete = false
					this.filters = {
						...this.filters,
						date_range: localStorage.getItem('admin-date-range') || '12-months',
						status: '',
					}

					Vue.toasted.show(`${data} draft bookings were deleted`)
				})
			}
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.bookings = []
				this.loadBookings()

				if (!this.filters.date_range.startsWith('-')) {
					localStorage.setItem('admin-date-range', this.filters.date_range)
				}
			},
		},
		sorting: {
			deep: true,
			handler() {
				if (this.pag.page !== 1) {
					this.pag.page = 1
				} else {
					// otherwise reload data now
					this.bookings = []
					this.loadBookings()
				}
				localStorage.setItem('admin-bookings-orderBy', this.sorting.orderBy)
				localStorage.setItem('admin-bookings-order', this.sorting.order)
			},
		},
		'pag.page'() {
			this.bookings = []
			this.loadBookings()
		},
		'pag.limit'() {
			this.bookings = []
			this.loadBookings()
			localStorage.setItem('admin-bookings-per-page', this.pag.limit)
		},
	},
}
</script>
