<template>
	<div v-if="person">
		<div class="page-admin-person">
			<div class="row align-items-center mb-4">
				<div class="col">
					<person-avatar
						:person="person"
						class="float-start me-2"
						:preview="false"
						:tooltip="false"
					></person-avatar>

					<h2 class="my-0">
						<span v-if="person.first_name || person.last_name">{{
							[person.first_name, person.middle_name, person.last_name].filter(Boolean).join(' ')
						}}</span>
						<small v-else class="text-neutral-300">(no name)</small>
						/
						<code v-if="person.email">{{ person.email }}</code>
						<small v-else class="text-neutral-300">(no email)</small>

						<small
							v-if="!person.first_name && !person.last_name && !person.email"
							class="ms-1 text-neutral-400"
							>({{ person.anonymous_name }})</small
						>
					</h2>
				</div>
				<div class="col-auto">
					<button
						v-if="person.email && !person.verified"
						class="btn btn-sm btn-outline-info ms-2"
						@click="updatePersonAccount((person.verified = true))"
					>
						Mark email as verified
					</button>
					<button
						class="btn btn-sm ms-2"
						:class="person.active ? 'btn-outline-danger' : 'btn-outline-success'"
						@click="updatePersonAccount((person.active = !person.active))"
					>
						{{ person.active ? 'Disable account' : 'Activate account' }}
					</button>
				</div>
			</div>

			<div class="card">
				<div class="card-body">
					<div class="row gx-2 text-center">
						<div class="col">
							<p class="mb-2">Munis</p>
							<p class="mb-0" v-if="uniqueJurisdictions.length">
								<small
									>{{
										uniqueJurisdictions
											.slice(0, 3)
											.map(pj => pj.jurisdiction_name)
											.join(', ')
									}}<span v-if="uniqueJurisdictions.length > 3">
										+ {{ uniqueJurisdictions.length - 3 }}</span
									></small
								>
							</p>
							<p v-else>
								❌
							</p>
						</div>
						<div class="col">
							<p>Active</p>
							<h3>{{ person.active ? '✅' : '❌' }}</h3>
						</div>
						<div class="col">
							<p>Email verified</p>
							<h3>{{ person.verified ? '✅' : '❌' }}</h3>
						</div>
						<div class="col">
							<p>Used phone app</p>
							<h3>{{ person.used_app ? '✅' : '❌' }}</h3>
						</div>
						<div class="col">
							<p class="mb-2">Created</p>
							<h3 class="mb-1">{{ person.created_at | dateLocal }}</h3>
							<p class="mb-0 text-muted">
								{{ person.created_at | dateToNow }}
							</p>
						</div>
						<div class="col">
							<p class="mb-2">Last login</p>
							<div v-if="person.last_login_at">
								<h3 class="mb-1">{{ person.last_login_at | dateLocal }}</h3>
								<p class="mb-0 text-muted">
									{{ person.last_login_at | dateToNow }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<p v-if="person.email" class="mt-2 mb-0">
				<a :href="`https://app.openreplay.com/3468/sessions?uid=is|${person.email}`" target="_blank"
					><small>Sessions in OpenReplay</small></a
				>
				&middot;
				<a
					:href="`https://account.postmarkapp.com/servers/9064802/streams/outbound/addresses/${person.email}`"
					target="_blank"
					><small>Emails in Postmark</small></a
				>
				&middot;
				<a :href="sendgridLink" target="_blank"><small>Emails in Sendgrid</small></a>
			</p>

			<ul class="nav nav-tabs mb-3 mt-4">
				<li class="nav-item">
					<router-link
						:to="`/heygov-admin/people/${person.id}`"
						class="nav-link"
						:class="{ active: $route.name === 'AdminPerson' }"
						>General</router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/heygov-admin/people/${person.id}/jurisdictions`"
						class="nav-link"
						:class="{ active: $route.name === 'AdminPersonJurisdictions' }"
						>Jurisdictions
						<span class="badge bg-light" :class="person.jurisdictions.length ? 'text-dark' : 'text-gray'">{{
							uniqueJurisdictions.length
						}}</span></router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/heygov-admin/people/${person.id}/issues`"
						class="nav-link"
						:class="{ active: $route.name === 'AdminPersonIssues' }"
						>Hey311
						<span
							v-if="person.jurisdictions.length"
							class="badge bg-light"
							:class="person.jurisdictions[0].count_threads ? 'text-dark' : 'text-gray'"
							>{{ person.jurisdictions[0].count_threads }}</span
						></router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/heygov-admin/people/${person.id}/form-requests`"
						class="nav-link"
						:class="{ active: $route.name === 'AdminPersonFormRequests' }"
						>HeyLicense
						<span
							v-if="person.jurisdictions.length"
							class="badge bg-light"
							:class="person.jurisdictions[0].count_form_requests ? 'text-dark' : 'text-gray'"
							>{{ person.jurisdictions[0].count_form_requests }}</span
						></router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/heygov-admin/people/${person.id}/venue-bookings`"
						class="nav-link"
						:class="{ active: $route.name === 'AdminPersonBookings' }"
						>HeyReserve
						<span
							v-if="person.jurisdictions.length"
							class="badge bg-light"
							:class="person.jurisdictions[0].count_venue_bookings ? 'text-dark' : 'text-gray'"
							>{{ person.jurisdictions[0].count_venue_bookings }}</span
						></router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/heygov-admin/people/${person.id}/payments`"
						class="nav-link"
						:class="{ active: $route.name === 'AdminPersonPayments' }"
						>HeyGovPay
						<span
							v-if="person.jurisdictions.length"
							class="badge bg-light"
							:class="person.jurisdictions[0].count_payments ? 'text-dark' : 'text-gray'"
							>{{ person.jurisdictions[0].count_payments }}</span
						></router-link
					>
				</li>
			</ul>

			<router-view :person="person"></router-view>
		</div>
	</div>
	<div v-else-if="states.person === 'loading'" class="text-center py-4">
		<div class="spinner-border" role="status"></div>
	</div>
</template>

<script>
import Vue from 'vue'
import { format } from 'date-fns'

import heyGovApi, { handleResponseError } from '@/api.js'
import PersonAvatar from '@/components/PersonAvatar.vue'

export default {
	name: 'AdminPerson',
	components: { PersonAvatar },
	data() {
		return {
			states: {
				person: 'loading',
			},
			person: null,
		}
	},
	computed: {
		sendgridLink() {
			return `https://app.sendgrid.com/email_activity?filters=[{"val"%3A["${this.person.email}"]%2C"selectedFieldName"%3A"to_email"%2C"comparisonType"%3A"Contains"}]`
		},
		uniqueJurisdictions() {
			return this.person.jurisdictions.reduce((unique, o) => {
				if (!unique.some(obj => obj.jurisdiction_id === o.jurisdiction_id)) {
					unique.push(o)
				}
				return unique
			}, [])
		},
	},
	created() {
		this.loadPerson()
	},
	methods: {
		loadPerson() {
			this.states.person = 'loading'

			heyGovApi
				.get(`super-secret-admin-endpoints/people/${this.$route.params.id}?expand=jurisdictions,authTokens`)
				.then(({ data }) => {
					if (data.dob) {
						data.dob = format(new Date(data.dob), 'yyyy-MM-dd')
					}

					this.person = data

					this.states.person = 'idle'
				}, handleResponseError(`Error loading person data ({error})`))
		},

		updatePersonAccount() {
			heyGovApi
				.put(`/super-secret-admin-endpoints/people/${this.person.id}`, {
					active: this.person.active,
					verified: this.person.verified,
				})
				.then(() => {
					Vue.toasted.success('Account is updated')
				})
		},
	},
}
</script>
